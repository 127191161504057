import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Projekte.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { TopbarPlanung } from '../../directives/components/topbar/TopbarPlanung';
import { FooterPlanung } from '../../directives/components/FooterPlanung';
import { TextboxBig } from '../../directives/components/util/TextboxBig';


//ingeniumwohngruppen
import BadEndorf_img_1 from './../../images/projects/BadEndorf/DSC09717-Pano.jpg';
import BadEndorf_img_2 from './../../images/projects/BadEndorf/DSC09756-Pano.jpg';
import BadEndorf_img_3 from './../../images/projects/BadEndorf/DSC09890.jpg';
import BadEndorf_img_4 from './../../images/projects/BadEndorf/DSC09899.jpg';
import BadEndorf_img_5 from './../../images/projects/BadEndorf/DSC09970-Pano.jpg';
import BadEndorf_img_6 from './../../images/projects/BadEndorf/DSC09733-Pano.jpg';
import BadEndorf_img_7 from './../../images/projects/BadEndorf/DSC09706-Pano.jpg';
import BadEndorf_img_8 from './../../images/projects/BadEndorf/DSC09759.jpg';
import BadEndorf_img_9 from './../../images/projects/BadEndorf/DSC09787-Pano.jpg';
import BadEndorf_img_10 from './../../images/projects/BadEndorf/DSC09814-Pano.jpg';
import BadEndorf_img_11 from './../../images/projects/BadEndorf/DSC09820-Pano.jpg';
import BadEndorf_img_12 from './../../images/projects/BadEndorf/DSC09868-Pano.jpg';
import BadEndorf_img_13 from './../../images/projects/BadEndorf/DSC09876-Pano.jpg';
import BadEndorf_img_14 from './../../images/projects/BadEndorf/DSC09916-Pano.jpg';
import BadEndorf_img_15 from './../../images/projects/BadEndorf/DSC09979-Pano.jpg';
import BadEndorf_img_16 from './../../images/projects/BadEndorf/DSC09988.jpg';


//ingeniumwohngruppen
import ingeniumwohngruppen_img_3 from './../../images/projects/ingeniumwohngruppen/DSC02229-Pano.jpg';
import ingeniumwohngruppen_img_2 from './../../images/projects/ingeniumwohngruppen/DSC02236-Pano.jpg';
import ingeniumwohngruppen_img_1 from './../../images/projects/ingeniumwohngruppen/DSC02250-Pano.jpg';


//ingeniumstiftung
import ingeniumstiftung_img_1 from './../../images/projects/ingeniumstiftung/Danuvius_Haus029.jpg';
import ingeniumstiftung_img_4 from './../../images/projects/ingeniumstiftung/Danuvius_Haus040.jpg';
import ingeniumstiftung_img_6 from './../../images/projects/ingeniumstiftung/Danuvius_Haus052.jpg';
import ingeniumstiftung_img_5 from './../../images/projects/ingeniumstiftung/Danuvius_Haus056.jpg';
import ingeniumstiftung_img_3 from './../../images/projects/ingeniumstiftung/Danuvius_Haus057.jpg';
import ingeniumstiftung_img_2 from './../../images/projects/ingeniumstiftung/Danuvius_Haus060.jpg';
import { Landing } from '../Landing';



//petershausen 
import petershausen_img_5 from './../../images/projects/petershausen/_MMP7987.jpg';
import petershausen_img_2 from './../../images/projects/petershausen/_MMP8058.jpg';
import petershausen_img_7 from './../../images/projects/petershausen/DSC02131-Pano.jpg';
import petershausen_img_4 from './../../images/projects/petershausen/DSC02137-Pano.jpg';
import petershausen_img_8 from './../../images/projects/petershausen/DSC02161-Pano.jpg';
import petershausen_img_6 from './../../images/projects/petershausen/DSC02164-Pano.jpg';
import petershausen_img_3 from './../../images/projects/petershausen/DSC02175.jpg';
import petershausen_img_1 from './../../images/projects/petershausen/DSC02179-Pano.jpg';
import petershausen_img_9 from './../../images/projects/petershausen/DSC02187-Pano.jpg';
import petershausen_img_11 from './../../images/projects/petershausen/IMG_6437.jpg';

//pfaffenhofen
import pfaffenhofen_img_1 from './../../images/projects/pfaffenhofen/_MG_1054.jpg';
import pfaffenhofen_img_4 from './../../images/projects/pfaffenhofen/_MG_1000.jpg';
import pfaffenhofen_img_3 from './../../images/projects/pfaffenhofen/_MG_1064.jpg';
import pfaffenhofen_img_7 from './../../images/projects/pfaffenhofen/_MG_1077.jpg';
import pfaffenhofen_img_5 from './../../images/projects/pfaffenhofen/_MG_1110.jpg';
import pfaffenhofen_img_9 from './../../images/projects/pfaffenhofen/_MG_1113.jpg';
import pfaffenhofen_img_2 from './../../images/projects/pfaffenhofen/_MG_8760.jpg';
import pfaffenhofen_img_6 from './../../images/projects/pfaffenhofen/_MG_8795.jpg';
import pfaffenhofen_img_8 from './../../images/projects/pfaffenhofen/_MG_8843.jpg';

//ingeniumwohngruppen
import BadEndorf_img_1Icon from './../../images/projectsIcons/BadEndorf/DSC09717-Pano.jpg';
import BadEndorf_img_2Icon from './../../images/projectsIcons/BadEndorf/DSC09756-Pano.jpg';
import BadEndorf_img_3Icon from './../../images/projectsIcons/BadEndorf/DSC09890.jpg';
import BadEndorf_img_4Icon from './../../images/projectsIcons/BadEndorf/DSC09899.jpg';
import BadEndorf_img_5Icon from './../../images/projectsIcons/BadEndorf/DSC09970-Pano.jpg';
import BadEndorf_img_6Icon from './../../images/projectsIcons/BadEndorf/DSC09733-Pano.jpg';
import BadEndorf_img_7Icon from './../../images/projectsIcons/BadEndorf/DSC09706-Pano.jpg';
import BadEndorf_img_8Icon from './../../images/projectsIcons/BadEndorf/DSC09759.jpg';
import BadEndorf_img_9Icon from './../../images/projectsIcons/BadEndorf/DSC09787-Pano.jpg';
import BadEndorf_img_10Icon from './../../images/projectsIcons/BadEndorf/DSC09814-Pano.jpg';
import BadEndorf_img_11Icon from './../../images/projectsIcons/BadEndorf/DSC09820-Pano.jpg';
import BadEndorf_img_12Icon from './../../images/projectsIcons/BadEndorf/DSC09868-Pano.jpg';
import BadEndorf_img_13Icon from './../../images/projectsIcons/BadEndorf/DSC09876-Pano.jpg';
import BadEndorf_img_14Icon from './../../images/projectsIcons/BadEndorf/DSC09916-Pano.jpg';
import BadEndorf_img_15Icon from './../../images/projectsIcons/BadEndorf/DSC09979-Pano.jpg';
import BadEndorf_img_16Icon from './../../images/projectsIcons/BadEndorf/DSC09988.jpg';


//ingeniumwohngruppen
import ingeniumwohngruppen_img_3Icon from './../../images/projectsIcons/ingeniumwohngruppen/DSC02229-Pano.jpg';
import ingeniumwohngruppen_img_2Icon from './../../images/projectsIcons/ingeniumwohngruppen/DSC02236-Pano.jpg';
import ingeniumwohngruppen_img_1Icon from './../../images/projectsIcons/ingeniumwohngruppen/DSC02250-Pano.jpg';


//ingeniumstiftung
import ingeniumstiftung_img_1Icon from './../../images/projectsIcons/ingeniumstiftung/Danuvius_Haus029.jpg';
import ingeniumstiftung_img_4Icon from './../../images/projectsIcons/ingeniumstiftung/Danuvius_Haus040.jpg';
import ingeniumstiftung_img_6Icon from './../../images/projectsIcons/ingeniumstiftung/Danuvius_Haus052.jpg';
import ingeniumstiftung_img_5Icon from './../../images/projectsIcons/ingeniumstiftung/Danuvius_Haus056.jpg';
import ingeniumstiftung_img_3Icon from './../../images/projectsIcons/ingeniumstiftung/Danuvius_Haus057.jpg';
import ingeniumstiftung_img_2Icon from './../../images/projectsIcons/ingeniumstiftung/Danuvius_Haus060.jpg';



//petershausen 
import petershausen_img_5Icon from './../../images/projectsIcons/petershausen/_MMP7987.jpg';
import petershausen_img_2Icon from './../../images/projectsIcons/petershausen/_MMP8058.jpg';
import petershausen_img_7Icon from './../../images/projectsIcons/petershausen/DSC02131-Pano.jpg';
import petershausen_img_4Icon from './../../images/projectsIcons/petershausen/DSC02137-Pano.jpg';
import petershausen_img_8Icon from './../../images/projectsIcons/petershausen/DSC02161-Pano.jpg';
import petershausen_img_6Icon from './../../images/projectsIcons/petershausen/DSC02164-Pano.jpg';
import petershausen_img_3Icon from './../../images/projectsIcons/petershausen/DSC02175.jpg';
import petershausen_img_1Icon from './../../images/projectsIcons/petershausen/DSC02179-Pano.jpg';
import petershausen_img_9Icon from './../../images/projectsIcons/petershausen/DSC02187-Pano.jpg';
import petershausen_img_11Icon from './../../images/projectsIcons/petershausen/IMG_6437.jpg';
//pfaffenhofenIcons
import pfaffenhofen_img_1Icon from './../../images/projectsIcons/pfaffenhofen/_MG_1054.jpg';
import pfaffenhofen_img_4Icon from './../../images/projectsIcons/pfaffenhofen/_MG_1000.jpg';
import pfaffenhofen_img_3Icon from './../../images/projectsIcons/pfaffenhofen/_MG_1064.jpg';
import pfaffenhofen_img_7Icon from './../../images/projectsIcons/pfaffenhofen/_MG_1077.jpg';
import pfaffenhofen_img_5Icon from './../../images/projectsIcons/pfaffenhofen/_MG_1110.jpg';
import pfaffenhofen_img_9Icon from './../../images/projectsIcons/pfaffenhofen/_MG_1113.jpg';
import pfaffenhofen_img_2Icon from './../../images/projectsIcons/pfaffenhofen/_MG_8760.jpg';
import pfaffenhofen_img_6Icon from './../../images/projectsIcons/pfaffenhofen/_MG_8795.jpg';
import pfaffenhofen_img_8Icon from './../../images/projectsIcons/pfaffenhofen/_MG_8843.jpg';


import { Projekte_Widget } from '../../directives/components/util/Projekte_Widget';
export default class ProjektePlanung extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh", position: "relative" }}>
        <div>
          <Page>
            <TopbarPlanung></TopbarPlanung>
            <Projekte_Widget imageNumber={6}
              Bauherr='Katharinenheim e.V.'
              Standort='Bad Endorf'
              Zeitraum='2008 - 2010'
              Typologie='Pflegeheim für dementiell Erkrankte'
              Auftrag="Generalplaner"
              reference={Landing.Projekt3}
              title="Bad Endorf" textboxTitle="Beispieltext" textboxDescription="Beispieltext"
              projects={[
                {
                  images: [
                    { iconpath: BadEndorf_img_1Icon, path: BadEndorf_img_1, name: "Projektbild_1", description: "Beschreibung 35" },
                    { iconpath: BadEndorf_img_2Icon, path: BadEndorf_img_2, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_3Icon, path: BadEndorf_img_3, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_4Icon, path: BadEndorf_img_4, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_5Icon, path: BadEndorf_img_5, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_6Icon, path: BadEndorf_img_6, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_7Icon, path: BadEndorf_img_7, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_8Icon, path: BadEndorf_img_8, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_9Icon, path: BadEndorf_img_9, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_10Icon, path: BadEndorf_img_10, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_11Icon, path: BadEndorf_img_11, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_12Icon, path: BadEndorf_img_12, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_13Icon, path: BadEndorf_img_13, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_14Icon, path: BadEndorf_img_14, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_15Icon, path: BadEndorf_img_15, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: BadEndorf_img_16Icon, path: BadEndorf_img_16, name: "Projektbild_2", description: "Beschreibung 2" }],
                  description: "Pflegeheim für dementiell Erkrankte gem. AVPfleWoqG; für demente Bewohner inkl. Therapieeinrichtungen (Bewegungsraum, Ruhebreichen), einem Inklusionscafe, Verwaltungs- und Technikflächen, Fläche für 3 Wohngruppen für 45 Bewohner, alle Bereiche des Pflegheims unter den Gesichtspunkten 'beschützte Unterbringung' mit Nullbarrierestatus. Fassade aus Holztafelbau in vorgefertigter Bauweise.",
                  name: "Bad Endorf - 1"
                }
              ]} />
            <Projekte_Widget imageNumber={9}
              Bauherr='Stiftung Ingenium'
              Standort='Ingolstadt'
              Zeitraum='2005 - 2006'
              Typologie='Pflegeheim'
              Auftrag="Generalplaner"
              reference={Landing.Projekt3}
              title="Pfaffenhofen" textboxTitle="Beispieltext" textboxDescription="Beispieltext"
              projects={[
                {
                  images: [
                    { iconpath: pfaffenhofen_img_1Icon, path: pfaffenhofen_img_1, name: "Projektbild_1", description: "Beschreibung 35" },
                    { iconpath: pfaffenhofen_img_2Icon, path: pfaffenhofen_img_2, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: pfaffenhofen_img_3Icon, path: pfaffenhofen_img_3, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: pfaffenhofen_img_4Icon, path: pfaffenhofen_img_4, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: pfaffenhofen_img_5Icon, path: pfaffenhofen_img_5, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: pfaffenhofen_img_6Icon, path: pfaffenhofen_img_6, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: pfaffenhofen_img_7Icon, path: pfaffenhofen_img_7, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: pfaffenhofen_img_8Icon, path: pfaffenhofen_img_8, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: pfaffenhofen_img_9Icon, path: pfaffenhofen_img_9, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Neubau eines Pflegeheimes für 50 dementiell Erkrankte, mit Therapieeinrichtungen, einer Tagespflege, Verwaltungs- und Technikflächen. Alle Bereiche des Pflegheims unter den Gesichtspunkten 'beschützte Unterbringung' mit Nullbarrierestatus. Fassade aus Holztafelbau in vorgefertigter Bauweise.",
                  name: "Projektname - 1"
                }
              ]} />
            <Projekte_Widget imageNumber={6}
              Bauherr='Stiftung Ingenium'
              Standort='Ingolstadt'
              Zeitraum='2011 - 2013'
              Typologie='Wohngruppenhaus'
              Auftrag="Generalplaner"
              reference={Landing.Projekt3}
              title="Ingeniumstiftung" textboxTitle="Beispieltext" textboxDescription="Beispieltext"
              projects={[
                {
                  images: [
                    { iconpath: ingeniumstiftung_img_1Icon, path: ingeniumstiftung_img_1, name: "Projektbild_1", description: "Beschreibung 1" },
                    { iconpath: ingeniumstiftung_img_2Icon, path: ingeniumstiftung_img_2, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: ingeniumstiftung_img_3Icon, path: ingeniumstiftung_img_3, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: ingeniumstiftung_img_4Icon, path: ingeniumstiftung_img_4, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: ingeniumstiftung_img_5Icon, path: ingeniumstiftung_img_5, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: ingeniumstiftung_img_6Icon, path: ingeniumstiftung_img_6, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Wohngruppenhaus mit 2 Wohngruppen für 18 dementiell erkrankte Bewohner und 9 Wohnungen für Senioren mit Einschränkungen (nullbarriere), sowie eine Besucherwohnung inkl. Verwaltung und Technikflächen. Alle Bereiche des Wohngruppenhauses unter den Gesichtspunkten 'beschützte Unterbringung' mit Nullbarrierestatus. Erschwernis: Keller im Grundwasser, ökologisch optimierte Bauausführung des Obergeschoßes in massiver Holzbauweise und Holz- Fassadenelementen.",
                  name: "Projektname - 1"
                }
              ]} />
            <Projekte_Widget imageNumber={6}
              Bauherr='Danuvius Klinik GmbH'
              Standort='Pfaffenhofen'
              Zeitraum='2008 - 2010'
              Typologie='Psychatrische Klinik'
              Auftrag="LPH1-9"
              reference={Landing.Projekt3}
              title="Petershausen" textboxTitle="Beispieltext" textboxDescription="Beispieltext"
              projects={[
                {
                  images: [
                    { iconpath: petershausen_img_1Icon, path: petershausen_img_1, name: "Projektbild_1", description: "Beschreibung 1" },
                    { iconpath: petershausen_img_2Icon, path: petershausen_img_2, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: petershausen_img_3Icon, path: petershausen_img_3, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: petershausen_img_4Icon, path: petershausen_img_4, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: petershausen_img_5Icon, path: petershausen_img_5, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: petershausen_img_6Icon, path: petershausen_img_6, name: "Projektbild_1", description: "Beschreibung 1" },
                    { iconpath: petershausen_img_7Icon, path: petershausen_img_7, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: petershausen_img_8Icon, path: petershausen_img_8, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: petershausen_img_9Icon, path: petershausen_img_9, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: petershausen_img_11Icon, path: petershausen_img_11, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Neubau einer Klinik für Psychiatrie und Psychiotherapie mit beschützendem Bereich für Gerontologie und Suizidgefährdete für 88 Patienten, Klinik mit maximaler Aufenthaltsqualität, auch für verlängerte Verweildauer mit Therapieeinrichtungen. Erschwernis; Starke Hanglage, sehr beengtes Baufeld, alle Bereiche unter den Gesichtspunkten 'beschützte Unterbringung' mit Nullbarrierestatus.",
                  name: "Projektname - 1"
                }
              ]} />
            <Projekte_Widget imageNumber={3}
              Bauherr='Privat'
              Standort='Petershausen'
              Zeitraum='2010 - 2012'
              Typologie='Pflegeheim für dementiell Erkrankte'
              Auftrag="Generalplaner"
              reference={Landing.Projekt3}
              title="Ingeniumwohngruppen" textboxTitle="Beispieltext" textboxDescription="Beispieltext"
              projects={[
                {
                  images: [
                    { iconpath: ingeniumwohngruppen_img_1Icon, path: ingeniumwohngruppen_img_1, name: "Projektbild_1", description: "Beschreibung 1" },
                    { iconpath: ingeniumwohngruppen_img_2Icon, path: ingeniumwohngruppen_img_2, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: ingeniumwohngruppen_img_3Icon, path: ingeniumwohngruppen_img_3, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Pflegeheim für dementiell Erkrankte gem. AVPfleWoqG, für 8 Wohngruppen mit 130 dementen Bewohnern inkl. Therapieeinrichtungen (Bewegungsraum, Ruhebreichen), einem Inklusionscafe, Verwaltungs- und Technikflächen, alle Bereiche des Pflegheims unter den Gesichtspunkten 'beschützte Unterbringung' mit Nullbarrierestatus. Ökologisch optimierte Bauausführung in Holzbauweise.",
                  name: "Projektname - 1"
                }
              ]} />
          </Page>
          <FooterPlanung></FooterPlanung>
        </div>
      </div>
    );
  }
}
