import React from 'react'

export interface IconProps {
  icon: string
  from?: "font-awesome" | "font-awesome-brand" | "font-awesome-regular"
  style?: React.CSSProperties
  className?: string
  onClick?: () => void
}
export class Icon extends React.PureComponent<IconProps> {
  render() {
    var iconClasses = "";
    switch (this.props.from) {
      case "font-awesome": iconClasses = `fas fa-${this.props.icon} ${this.props.className}`; break;
      case "font-awesome-brand": iconClasses = `fab fa-${this.props.icon} ${this.props.className}`; break;
      case "font-awesome-regular": iconClasses = `far fa-${this.props.icon} ${this.props.className}`; break;
      default: iconClasses = `fas fa-${this.props.icon}`; break;
    }
    return <span className={`${this.props.className || ""} ${iconClasses}`} {...{
      style: this.props.style,
      onClick: this.props.onClick
    }} />
  }
}
