import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Landing.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../directives/pagepresets/Page';

import ZAP_Logo from '../images/logos/ZAP_logo_Clean.png';

function isElementInViewport(el: HTMLDivElement | null): boolean {
  if (el == null)
    return false;
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}
export class Landing extends React.Component<{}> {
  public oekologie = React.createRef<HTMLDivElement>();
  public oekonomie = React.createRef<HTMLDivElement>();
  public smarthome = React.createRef<HTMLDivElement>();
  public static Team = React.createRef<HTMLDivElement>();
  public static Projekte = React.createRef<HTMLDivElement>();
  public static Kontakt = React.createRef<HTMLDivElement>();
  public static Archiv = React.createRef<HTMLDivElement>();
  public static Profil = React.createRef<HTMLDivElement>();
  public static Top = React.createRef<HTMLDivElement>();


  public static Aktuelles = React.createRef<HTMLDivElement>();
  public static Projekt1 = React.createRef<HTMLDivElement>();
  public static Projekt2 = React.createRef<HTMLDivElement>();
  public static Projekt3 = React.createRef<HTMLDivElement>();
  public static Projekt4 = React.createRef<HTMLDivElement>();

  state = {
    AktuellesInViewport: false,
    ProjektInViewport1: false,
    ProjektInViewport2: false,
    ProjektInViewport3: false,
    ProjektInViewport4: false,
  }



  componentDidMount = () => {
    window.onscroll = () => {
      // called when the window is scrolled. 
      this.setState({
        AktuellesInViewport: isElementInViewport(Landing.Aktuelles.current),
        ProjektInViewport1: isElementInViewport(Landing.Projekt1.current),
        ProjektInViewport2: isElementInViewport(Landing.Projekt2.current),
        ProjektInViewport3: isElementInViewport(Landing.Projekt3.current),
        ProjektInViewport4: isElementInViewport(Landing.Projekt4.current),

      })
      console.log(this.state.AktuellesInViewport, "Aktuelles in Viewport");
      console.log(this.state.ProjektInViewport1, "1 in Viewport");
      console.log(this.state.ProjektInViewport2, "2 in Viewport");
      console.log(this.state.ProjektInViewport3, "3 in Viewport");
      console.log(this.state.ProjektInViewport4, "4 in Viewport");

    }
  }

  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }} >
        <div >
          <Page centered={true}>
            <div className={`${Container("column center center")}`}>
              <div className={`${Container("column center stretch")}  `} style={{}}>

                <div className="videoPadding embed-responsive embed-responsive-16by9 marginPlaceholder-large ">
                  {/* <video poster={ZAP_Logo} style={{ zIndex: 2 }} className="" src="/videologo.mp4" controlsList="nodownload" autoPlay={true} muted  ></video> */}
                  <video autoPlay preload='auto' muted playsInline poster={ZAP_Logo} style={{ zIndex: 2 }} src="/ipad.mp4" ></video>
                </div>
                <div className={`${Container("column between stretch")}`} style={{ width: "100%" }}>
                  <div style={{ borderBottom: "3px solid #adadad", marginBottom: "3vh" }} className={`${Flex({ xs: { flex: "1" } })} ${Container({ xs: "column center center", sm: "column center start" })}`} >
                    <a href="/Partnerschaften/Projekte" className={`fontBlack fakelink headerFontSize ${Flex({ xs: { flex: "1" } })} ${Container("row between center")}`} style={{ width: "100%", marginBottom: "3vh" }} >
                      <span style={{ fontWeight: 'bold', color: "#adadad", fontStyle: "italic", wordBreak: 'break-all' }} >ARCHITEKTEN PARTNERSCHAFT </span>
                      <span style={{ fontWeight: 'bold', color: "#adadad", fontStyle: "italic" }} >GmbB </span>
                    </a>
                  </div>

                  <div className={`${Flex({ xs: { flex: "1" } })} ${Container({ xs: "column center center", sm: "column center end" })}`} >
                    <a href="/Planungsgesellschaft/Projekte" className={`fontBlack fakelink headerFontSize ${Flex({ xs: { flex: "1" } })} ${Container("row between center")}`} style={{ width: "100%" }}>
                      <span style={{ fontWeight: 'bold', color: "#adadad", fontStyle: "italic", wordBreak: 'break-all' }} >PLANUNGSGESELLSCHAFT&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  </span>
                      <span style={{ fontWeight: 'bold', color: "#adadad", fontStyle: "italic" }} >GmbH </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Page >

        </div >
        <div >

          {/* 
                   <Topbar variant="white" />
<Page>
            <div >
              <Aktuelles_Widget
                reference={Landing.Aktuelles}
                carouselEnabled={this.state.AktuellesInViewport}
                description={<> Beschreibung </>}
                height={400}
                images={[project_1_img_1, project_1_img_2, project_1_img_3]}
                textBoxes={[
                  {
                    description: "Bildbeschreibung - 1",
                    title: "Bildtitel - 1"
                  },
                  {
                    description: "Bildbeschreibung - 2",
                    title: "Bildtitel - 2"
                  },
                  {
                    description: "Bildbeschreibung - 3",
                    title: "Bildtitel - 3"
                  }]} />
            </div>
            <div ref={Landing.Profil}>
              <Profil_Widget description={<div className={`${Container("row start stretch")}`}>
                Beispiel Text mit
                <a onClick={() => {
                  if (this.oekologie && this.oekologie.current) {
                    this.oekologie.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }
                }> Ökologie </a>
                <a onClick={() => {
                  if (this.oekonomie && this.oekonomie.current) {
                    this.oekonomie.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }
                }> Ökonomie </a>
                <a onClick={() => {
                  if (this.smarthome && this.smarthome.current) {
                    this.smarthome.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }
                }> Smarthome </a>
              </div>} />
            </div>
            <div ref={Landing.Projekte}>
              <h2 className="h2Header">Projekte</h2>
              <div >
                <Projekte_Widget
                  reference={Landing.Projekt1}
                  carouselEnabled={this.state.ProjektInViewport1}
                  title="Pflege und Gesundheit"
                  textboxTitle="Einleitung Pflege"
                  textboxDescription="Beispieltext"
                  height={600}
                  projects={[
                    {
                      images: [{ path: project_2_img_1, name: "Projektbild_1", description: "Projektbildbeschreibung_1" }, { path: project_1_img_1, name: "Projektbild_2", description: "Projektbildbeschreibung_2" }, { path: project_1_img_2, name: "Projektbild_3", description: "Projektbildbeschreibung_3" }],
                      description: "Projektbeschreibung - 1",
                      name: "Projektname - 1"
                    },
                    {
                      images: [{ path: project_2_img_2, name: "Projektbild_1", description: "Projektbildbeschreibung_1" }, { path: project_1_img_2, name: "Projektbild_2", description: "Projektbildbeschreibung_2" }, { path: project_1_img_3, name: "Projektbild_3", description: "Projektbildbeschreibung_3" }],
                      description: "Projektbeschreibung - 2",
                      name: "Projektname - 2"
                    },
                    {
                      images: [{ path: project_2_img_3, name: "Projektbild_1", description: "Projektbildbeschreibung_1" }, { path: project_1_img_3, name: "Projektbild_2", description: "Projektbildbeschreibung_2" }, { path: project_1_img_1, name: "Projektbild_3", description: "Projektbildbeschreibung_3" }],
                      description: "Projektbeschreibung - 3",
                      name: "Projektname - 3"
                    }
                  ]} />
              </div>
              <div >
                <Projekte_Widget
                  reference={Landing.Projekt2}

                  carouselEnabled={this.state.ProjektInViewport2}
                  title="Privat"
                  textboxTitle="Einleitung Privates Bauen"
                  textboxDescription="Beispieltext"
                  height={600}
                  projects={[
                    {
                      images: [{ path: project_2_img_1, name: "Projektbild_1" }, { path: project_1_img_1, name: "Projektbild_2" }, { path: project_1_img_2, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 1",
                      name: "Projektname - 1"
                    },
                    {
                      images: [{ path: project_2_img_2, name: "Projektbild_1" }, { path: project_1_img_2, name: "Projektbild_2" }, { path: project_1_img_3, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 2",
                      name: "Projektname - 2"
                    },
                    {
                      images: [{ path: project_2_img_3, name: "Projektbild_1" }, { path: project_1_img_3, name: "Projektbild_2" }, { path: project_1_img_1, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 3",
                      name: "Projektname - 3"
                    }
                  ]} /></div>
              <div >
                <Projekte_Widget
                  reference={Landing.Projekt3}

                  carouselEnabled={this.state.ProjektInViewport3}
                  title="Sanierung" textboxTitle="Einleitung Sanierung" textboxDescription="Beispieltext"
                  height={600}
                  projects={[
                    {
                      images: [{ path: project_2_img_1, name: "Projektbild_1" }, { path: project_1_img_1, name: "Projektbild_2" }, { path: project_1_img_2, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 1",
                      name: "Projektname - 1"
                    },
                    {
                      images: [{ path: project_2_img_2, name: "Projektbild_1" }, { path: project_1_img_2, name: "Projektbild_2" }, { path: project_1_img_3, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 2",
                      name: "Projektname - 2"
                    },
                    {
                      images: [{ path: project_2_img_3, name: "Projektbild_1" }, { path: project_1_img_3, name: "Projektbild_2" }, { path: project_1_img_1, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 3",
                      name: "Projektname - 3"
                    }
                  ]} /></div>
              <div >
                <Projekte_Widget
                  reference={Landing.Projekt4}
                  carouselEnabled={this.state.ProjektInViewport4}
                  title="Sanierung" textboxTitle="Einleitung Sanierung" textboxDescription="Beispieltext"
                  height={600}
                  projects={[
                    {
                      images: [{ path: project_2_img_1, name: "Projektbild_1" }, { path: project_1_img_1, name: "Projektbild_2" }, { path: project_1_img_2, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 1",
                      name: "Projektname - 1"
                    },
                    {
                      images: [{ path: project_2_img_2, name: "Projektbild_1" }, { path: project_1_img_2, name: "Projektbild_2" }, { path: project_1_img_3, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 2",
                      name: "Projektname - 2"
                    },
                    {
                      images: [{ path: project_2_img_3, name: "Projektbild_1" }, { path: project_1_img_3, name: "Projektbild_2" }, { path: project_1_img_1, name: "Projektbild_3" }],
                      description: "Projektbeschreibung - 3",
                      name: "Projektname - 3"
                    }
                  ]} />
              </div>
            </div>
            <div ref={Landing.Team}>
              <Team_Widget title="Team" textboxTitle="Einleitung Team" textboxDescription="Beispieltext"
                height={500}
                teammitglied={[
                  {
                    name: "Person_Name - 1",
                    description: "PersonBeschreibung - 1",
                    image: team_1
                  },
                  {
                    name: "Person_Name - 2",
                    description: "PersonBeschreibung - 2",
                    image: team_2
                  },
                  {
                    name: "Person_Name - 3",
                    description: "PersonBeschreibung - 3",
                    image: team_3
                  },
                  {
                    name: "Person_Name - 4",
                    description: "PersonBeschreibung - 4",
                    image: team_4
                  },
                  {
                    name: "Person_Name - 5",
                    description: "PersonBeschreibung - 5",
                    image: team_5
                  }
                ]} />
            </div>
            <div ref={Landing.Archiv} className="marginPlaceholder" >
              <h2 className="h2Header">Archiv Team</h2>
              <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}
                title={`Einleitung Archiv Team`}
                decsription={`Archiv Beschreibung`}
              />
            </div>
            <div className="marginPlaceholder" >
              <h2 className="h2Header">Archiv Projekte</h2>
              <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}
                title={`Einleitung Archiv Team`}
                decsription={`Archiv Beschreibung`}
              />
            </div>
            <div className="marginPlaceholder" >
              <h2 className="h2Header">Erweitertes Profil</h2>
              <div ref={this.oekonomie} className="marginPlaceholder-small" >
                <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}
                  title={`Ökonomie`}
                  decsription={`Ökonomie Beschreibung`}
                />
              </div>
              <div ref={this.oekologie} className="marginPlaceholder-small" >
                <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}
                  title={`Ökologie`}
                  decsription={`Ökologie Beschreibung`}
                />
              </div>
              <div ref={this.smarthome} className="marginPlaceholder-small" >
                <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}
                  title={`Smarthome`}
                  decsription={`Smarthome Beschreibung`}
                />
              </div>
            </div>
            <div className={`${Container("column center stretch")} marginPlaceholder`}>
              <div className={`${Container("row between stretch")}`}>
                <div className={`${Container("column center stretch")}`}>
                  ZAP
                  <img
                    style={{ objectPosition: "0% 30%", objectFit: "contain", width: "125px" }}
                    src={ZAP_Logo}
                    alt="Image Loading"
                  />
                  <div className="marginPlaceholder small"> </div>
                  <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}></Textbox>
                </div>
                <div className={`${Container("column center stretch")}`}>
                  ZPG
                  <img
                    style={{ objectPosition: "0% 30%", objectFit: "contain", width: "125px" }}
                    src={ZAP_Logo}
                    alt="Image Loading"
                  />
                  <div className="marginPlaceholder small"> </div>
                  <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}></Textbox>
                </div>
                <div className={`${Container("column center stretch")}`}>
                  ABZ
                  <img
                    style={{ objectPosition: "0% 30%", objectFit: "contain", width: "125px" }}
                    src={ZAP_Logo}
                    alt="Image Loading"
                  />
                  <div className="marginPlaceholder small"> </div>
                  <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}></Textbox>
                </div>
              </div>
            </div>
            {/* <ProjectButtonDesign1
                                        images={[project_2_img_1, project_2_img_2, project_2_img_3]}
                                        textBoxes={[
                                          {
                                            link: "/",
                                            description: "Beispieltext",
                                            title: "Büro"
                                          },
                                          {
                                            link: "/",
                                            description: "Beispieltext",
                                            title: "Pflege"
                                          }]} /> */}
          {/* <StefanDivider width={50}></StefanDivider>

                                      <div>
                                        <div className={`${Container("column center stretch")} marginPlaceholder `}>

                                          <Projekte_Widget title="Team" textboxDescription="Beispieltext"
                                            height={500}
                                            projects={[
                                              {
                                                images: [{ path: team_1, name: "Projektbild_1" }],
                                                description: "Personen-Aktivitäten, Beschreibung - 1",
                                                name: "Person - 1"
                                              },
                                              {
                                                images: [{ path: team_2, name: "Projektbild_1" }],
                                                description: "Personen-Aktivitäten, Beschreibung - 2",
                                                name: "Person - 2"
                                              },
                                              {
                                                images: [{ path: team_3, name: "Projektbild_1" }],
                                                description: "Personen-Aktivitäten, Beschreibung - 3",
                                                name: "Person - 3"
                                              }
                                            ]} />

                                        </div>
                                      </div> 
          </Page> */}
        </div>
      </div >
    );
  }
}
