import { Container, Flex } from "@quesstor/react-flex-layout";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "./Icon";
import './TeamMitglied.css';

export class TeamMitglied extends React.Component<{
    surname?: string;
    name?: string;

    description?: string | JSX.Element;
    image?: string;
    rolle?: string;
    hasExtraRole?: string;
}> {
    state = { showTeamText: false }

    render() {
        return (
            <div style={{ padding: "25px" }} className={`${Flex({ xs: { flex: "1" } })}`}>
                <div className={`${Container("column center stretch")} `} style={{ position: "relative", width: "100%" }}>
                    <img className={`teamImage`}
                        style={{ opacity: 1 }}
                        src={this.props.image || '/images/placeholder/placeholder.png'}
                        alt="Image Loading"
                    />

                    <div className={`teamImage ${Container("column end start")} `}
                        style={{
                            backgroundColor: "white",
                            letterSpacing: "8px", color: "rgb(209 209 209 / 100%)", position: "absolute",
                            height: "90%", width: "100%",
                            objectFit: "contain",
                            fontSize: "1.1em",
                        }}>
                        <span style={{ display: "block", maxWidth: "180.72px" }}>{this.props.surname || ""}</span>
                        <span style={{}}>{this.props.name || ""}</span>
                        <span style={{ display: "block", maxWidth: "180.72px" }}>{this.props.rolle || ""}</span>
                        <span style={{ display: "block", maxWidth: "180.72px" }}>{this.props.description || ""}</span>
                        <span style={{ display: "block", maxWidth: "180.72px" }}>{this.props.hasExtraRole || ""}</span>
                    </div>

                    {/* <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Beschreibung</Tooltip>}
                    >
                        <div style={{ position: "absolute", bottom: 10, right: "calc(50% - (45px / 2))", zIndex: 10 }}  >
                            <div
                                style={{ fontSize: 45, color: "white", cursor: "pointer" }}
                                onClick={() => this.setState({ showTeamText: !this.state.showTeamText })}
                                className={`${Container("column center center")}`} >
                                <>
                                    <Icon icon={`${!this.state.showTeamText ? "plus" : "minus"}`} />
                                </>
                            </div>
                        </div>
                    </OverlayTrigger> */}
                    {/* <Textbox bg={true} hasPadding={true} hasNoMarginPlaceholder={true}
                            title={this.props.teammitglied[0].name}
                            decsription={this.props.teammitglied[0].description}
                        /> */}
                </div>
            </div >
        )
    }
} 
