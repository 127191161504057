import { Container } from "@quesstor/react-flex-layout";
import React from "react";

export class Page extends React.Component<{
    centered?: boolean;
    minHeightMainComp?: boolean;
}> {
    render() {
        return (
            <div style={{}}>
                <div className={`${Container({ xs: this.props.centered ? "column center stretch" : "column start stretch" })}`} style={{ color: "#5f5f5f", minHeight: "100vh", width: "100vw", margin: 0 }}>
                    <div className={`${Container("column center stretch")}`}>
                        <div className={`${Container("column center center")}`}>
                            <div className="mainComp" style={{ minHeight: this.props.minHeightMainComp ? "89vh" : "" }}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
