import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Datenschutz.css';
import 'react-awesome-slider/dist/styles.css';
import { Page } from '../directives/pagepresets/Page';
import { TopbarPartner } from '../directives/components/topbar/TopbarPartner';
import { FooterPartner } from '../directives/components/FooterPartner';
import { TextboxBig } from '../directives/components/util/TextboxBig';

import { TopbarPlanung } from '../directives/components/topbar/TopbarPlanung';
import { FooterPlanung } from '../directives/components/FooterPlanung';

export default class Datenschutz extends React.Component<{
  topbarPlanung?: Boolean;
}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page minHeightMainComp={true}>
            {this.props.topbarPlanung ? <TopbarPlanung></TopbarPlanung> : <TopbarPartner></TopbarPartner>}

            <TextboxBig smallFont={true} onThreeAspects={true} title={''} noLetterSpacing={true} noWordSpacing={true} noLineHeightCHange={true}
              decsription={
                <>
                  <span>
                    1. Datenschutz auf einen Blick<br />
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten , mit denen Sie persönlich identifiziert werden
                    können . Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                    <br /><br />2. Allgemeine Hinweise und Pflichtinformationen<br />
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten
                    wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht . Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) , Sicherheitslücken aufweisen kann. Ein
                    lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                    <br /><br />Hinweis zur verantwortlichen Stelle
                    <br />Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br />
                    Ziersch Architekten Part GmbB<br />
                    Grawolfstraße 1, 82166 Gräfelfing<br />
                    Telefon +49 (0)89 85484804
                    <br /><br />Verantwortliche Stelle ist die natürliche oder juristische Person , die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-mail-Adressen o. ä.) entscheidet.
                    <br />Auskunft, Sperrung , Löschung
                    <br /><br />Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf.
                    ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden .
                    Sie können gemäß Art. 21 DSGVO in den dort genannten Fällen Widerspruch gegen die Verarbeitung Ihrer Daten erheben . Bitte wenden Sie sich an info@za-p.de oder senden Sie uns Ihr Verlangen per Post.
                    <br /><br />Sie haben das Recht zur Beschwerde bei der zuständigen Aufsichtsbehörde für den Datenschutz:<br />
                    Bayerisches Landesamt für Datenschutzaufsicht (BayLDA)<br />
                    Promenade 27<br />
                    91522 Ansbach<br />
                    Telefon: 0981 531300<br />
                    Telefax: 0981 53981300<br />
                    E-Mail: poststelle@lda.bayern .de<br />
                    Web-Adresse: https://www .lda.bayern.de/de/index.html#
                    <br /><br />3. Datenerfassung auf unserer Website<br />
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind
                    kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies” . Sie werden nach Ende Ihres Besuchs automatisch gelöscht . Andere
                    Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen . Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen . Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
                    informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von
                    Cookies kann die Funktionalität dieser Website eingeschränkt sein. Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion)
                    erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit
                    andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                    <br /><br />Server-Log-Dateien<br />
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                    Browsertyp und Browserversion, verwendetes Betriebssystem, Referrer URL, Hostname des zugreifenden Rechners, Uhrzeit der Serveranfrage, IP-Adresse.
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO , der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
                    Maßnahmen gestattet.
                    <br /><br />4. Instagram<br />
                    Diese Seite nutzt über eine API das soziale Netzwerk Instagram. Anbieter ist die Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA . Zur Nutzung der Funktionen von Instagram ist es notwendig, Ihre IP Adresse zu speichern. Diese
                    Informationen werden in der Regel an einen Server von Instagram in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung. Die Nutzung von Instagram erfolgt im Interesse einer
                    ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Instagram:
                    https://help.instagram.com/519522125107875?helpref=page_content
                  </span>
                </>
              } />
          </Page>
          {this.props.topbarPlanung ? <FooterPlanung></FooterPlanung> : <FooterPartner></FooterPartner>}
        </div>
      </div>
    );
  }
}
