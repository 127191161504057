import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './ArchivPlanung.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { TopbarPlanung } from '../../directives/components/topbar/TopbarPlanung';
import { FooterPlanung } from '../../directives/components/FooterPlanung';
import { TextboxBig } from '../../directives/components/util/TextboxBig';

export default class ArchivPlanung extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page>
            <TopbarPlanung></TopbarPlanung>
            <div className={`${Container("column start stretch")} `}>
              <TextboxBig noLetterSpacing={true} noLineHeightCHange={true} noWordSpacing={true} smallFont={true}
                title={'Archiv Projekte Gewerbe:'}
                decsription={
                  <>
                    <span>
                      Neubau Klinik für Psychiatrie und Psychiotherapie | Pfaffenhofen | Bauherr: Danuvius Klinik | Baujahr: 2010 | Bauvolumen: 7,5 mio. LP 1 - 9 || Neubau 2
                      Wohngruppenhäuser für demenziell Erkrankte und Seniorenwohnen | Ingolstadt | Bauherr: Stiftung Ingenium | Baujahr: 2011 | Bauvolumen: 4,5 mio. LP 1 - 9 || Neubau
                      von 2 Wohngruppenhäusern für dementiell Erkrankte | Kinding | Bauherr: Schwarzachhaus GmbH | Baujahr: 2010 | Bauvolumen: 2,5 mio. LP 1 - 4 || Wettbewerb: Umbau
                      eines Pflegzentrums zum Gästezentrum | Bauherr: Rotes Kreuz Biberach | Baujahr: 2010 <b>3. Preis</b> || Umbau und Umnutzung der Danuvius Klinik in Ingolstadt | Bauherr:
                      Danuvius Klinik | Baujahr: 2015 | Bauvolumen: <b>3,0 mio. LP 1 - 3</b> || Neubau Pflegeheim für dementiell Erkrankte, Petershausen | Bauherr: Petershausen Immobilien GmbH
                      | Baujahr: 2016 | Bauvolumen: <b>7,5 mio. LP 1 - 9</b> || Neubau Pflegeheim für dementiell Erkrankte | Lenting | Baujahr: 2018 | Bauvolumen:<b> 4,0 mio. LP 1 - 9</b> || Neubau
                      Pflegeheim für dementiell Erkrankte | Bad Endorf | Bauherr: Katharinenheim e.V. | Baujahr: 2020 | Bauvolumen: <b>5,0 mio. LP 1 - 9</b> || Neubau einer Großküche, Bad Endorf
                      | Bauherr: Katharinenheim e.V. | Baujahr: 2019 | Bauvolumen:<b> 2,0 mio. LP 1 - 3 </b>||
                    </span>
                  </>
                } />
              {/* <TextboxBig spec={true} noLetterSpacing={true} noLineHeightCHange={true} noWordSpacing={true} smallFont={true}
                title={'Archiv Mitarbeiter:'}
                decsription={
                  <>
                    <span>
                      Ziersch Architekten<br />
                      Partnerschaft GmbB<br />
                      Grawolfstraße 1 82166 Gräfelfing<br />
                      www.za-p.de 089 85484804 info@za-p.de<br />
                    </span>
                  </>
                } /> */}
            </div>
          </Page>
          <FooterPlanung></FooterPlanung>
        </div>
      </div>
    );
  }
}
