import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Aktuell.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { FooterPlanung } from '../../directives/components/FooterPlanung';
import { TextboxBig } from '../../directives/components/util/TextboxBig';
import { TopbarPlanung } from '../../directives/components/topbar/TopbarPlanung';
import Aktuelles from '../../directives/components/util/Aktuelles';

//Aktuelles
import Graef2 from './../../images/projects/Aktuell/Pflege Gräfelfing_A_Cam02_Platz_P03_Auschnitt.jpeg';
import Graef1 from './../../images/projects/Aktuell/Pflege Gräfelfing_A_Cam01_Eingang_P03_Auschnitt.jpeg';
import BadEndorf from './../../images/projects/BadEndorf/DSC09717-Pano.jpg';




export default class AktuellPlanung extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page>
            <TopbarPlanung></TopbarPlanung>
            <div className={`${Container("column start stretch")} `}>

              <Aktuelles
                header="T e g e r n s e e R o t t a c h - E g e r n 2 0 2 1 - 2 2"
                text="Entwicklung des Konzeptes für den neuen Schwaighof"
              >
              </Aktuelles>
              <Aktuelles
                header="G r ä f e l f i n g 2 0 2 1 - 2 4"
                text="Neubau eines Pflegeheims als Ersatz für das Rudolf und Maria Gunst Haus in Gräfelfing mit 96 Bewohnern"
                image={Graef1}
              >            </Aktuelles>
              <Aktuelles
                header="G r ä f e l f i n g 2 0 2 2 - 2 5"
                text="Neubau von 60 Wohnungen für Wohnen mit Service mit Speiseraum und Wellness im Untergeschoss"
                image={Graef2}
              >            </Aktuelles>
              <Aktuelles
                header="B a d E n d o r f 2 0 1 9 - 2 0 2 1"
                text="Fertigstellung des neuen Pflegeheims für den Katharinenheim Endrof e.V."
                image={BadEndorf}
              >            </Aktuelles>
              {/* <div className={`${Container("column start stretch")} `}>
                <TextboxBig spec={true}
            title={'Vorstellung'} 
            decsription={
                <>
                  Die Ziersch Planungs GmbH greift auf die Erfahrung des Teams des Architekturbüros Bertold Ziersch zurück. Die
                  langjährige Zusammenarbeit mit versierten und erfahrenen Fachplanern gibt die Garantie mit einem eingespielten Team
                  zu arbeiten.
                </>
                } />
            <TextboxBig spec={true}
            title={'Kompetenz'} 
            decsription={
                <>
                  Die ziersch planungs gmbh hat ihren Fokus auf Bauten für Pflege und Gesundheit gerichtet. Das Architekturbüro Bertold
                  Ziersch hat das Pflegeheim der Stiftung Ingenium in Ingolstadt erstellt. Dieses Pflegeheim wurde mit dem international
                  ausgelobten 1. Preis der Gradmannstiftung ausgezeichnet.<br/>
                  Während der Planungsphase wurde deutlich wie hilfreich eine reibungsfreie Zusammenarbeit aller Projektbeteiligten bei
                  der Gebäudeentwicklung ist und wo Optimierungspotentiale in diesem Prozess stecken. Die Planungs GmbH wurde
                  ergänzend zum Architekturbüro Bertold Ziersch gegründet, um Generalplanerleistungen anbieten zu können.
                </>
                } />
            <TextboxBig spec={true}
            title={'Leistung'} 
            decsription={
                <>
                  Das Leistungsspektrum umfasst alle notwendigen Planungsleistungen. Der Bauherr erhält mit Vertragsunterzeichnung
                  alle Fachplanerleistungen, die zur Realisierung eines Bauvorhabens notwendig sind , aus einer Hand d.h. die
                  Notwendigkeit einer zusätzlichen Projektsteuerung entfällt, da weder Schnittstellenprobleme entstehen können, noch
                  der interne Informationsaustausch strukturiert werden muss.<br/>
                  Es werden die Leistungen abgedeckt zur Erstellung von:
                  <li>
                  Flächennutzungsplänen
                  </li><li>
                  Bebauungsplänen
                  </li><li>
                  Entwurfs- , Eingabe- Detail- und Werkplänen
                  </li><li>
                  Ausschreibung
                  </li><li>
                  Vergaben und
                  </li><li>
                  Abrechnung
                  </li>
                  Die Planungen der Gebäudetechnik und Statik , alle Leistungen im Rahmen von Brandschutz und Energieoptimierung
                  sowie die Planung der Außenanlagen sind darin enthalten . Für die Bauleitung stehen erfahrene Bauingenieure aus dem
                  Planerteam zur Verfügung. Die Leistungen werden durch eine projektbezogene Versicherung abgedeckt und enden mit
                  der mängelfreien Übergabe des Bauvorhabens an den Bauherren.       
                </>
                } />
          </div> */}
            </div>

          </Page>

          <FooterPlanung></FooterPlanung>
        </div>
      </div>
    );
  }
}
