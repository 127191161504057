import { Container, hide } from "@quesstor/react-flex-layout";
import React from "react";
import { Landing } from "../../../pages/Landing";
import "./TopbarLink.css";


export default class TopbarLink extends React.Component<{
    link: any;
    className?: string;
    color?: string;
    title: string;
    special?: boolean;
    onClick?: () => void
}> {
    render() {
        return (
            <a style={{ color: this.props.color ? this.props.color : '', position: 'relative' }} href={this.props.link}
                // onClick={() => {
                //     if (this.props.link && this.props.link.current) {
                //         this.props.link.current.scrollIntoView({
                //             behavior: "smooth",
                //             block: "start"
                //         });
                //     }
                // }
                //, textDecoration: window.location.pathname == this.props.link ? "underline" : "none"
                // } 
                className={`white Topbarlink ${this.props.className}`} >
                <div className={`${Container("row start center")} innerText`} style={{ position: 'relative' }}>
                    <div style={{ fontWeight: window.location.pathname == this.props.link ? "bold" : 'initial', color: this.props.special ? "red" : window.location.pathname == this.props.link ? "#5f5f5f" : "#adadad", textTransform: 'uppercase' }} >{this.props.title}</div>
                </div >
            </a >
        )
    }
}
