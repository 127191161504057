import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './TeamPlanung.css';
import 'react-awesome-slider/dist/styles.css';
//TEAM
import david from '../../images/team/DSC02836.jpg';
import berthold from '../../images/team/DSC02875.jpg';
import dora from '../../images/team/DSC02829.jpg';
import kathrin from '../../images/team/DSC02847.jpg';
import günter from '../../images/team/DSC02856.jpg';
import martin from '../../images/team/DSC02889.jpg';
import gundula from '../../images/team/DSC02880.jpg';
//BüroFinal
import buerofinal_img_1 from './../../images/projects/buerofinal/buero1.jpg';
import buerofinal_img_2 from './../../images/projects/buerofinal/buero2.jpg';
import { Page } from '../../directives/pagepresets/Page';
import { TopbarPartner } from '../../directives/components/topbar/TopbarPartner';
import { FooterPartner } from '../../directives/components/FooterPartner';
import { TextboxBig } from '../../directives/components/util/TextboxBig';
import { Team_Widget } from '../../directives/components/util/Team_Widget';
import { TeamMitglied } from '../../directives/components/util/TeamMitglied';
import { TopbarPlanung } from '../../directives/components/topbar/TopbarPlanung';
import { FooterPlanung } from '../../directives/components/FooterPlanung';

export default class TeamPlanung extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page>
            <TopbarPlanung></TopbarPlanung>
            <div className={`${Container("column between stretch")}`}>
              <div className={`marginPlaceholder ${Container({ xs: "row center center", sm: "row center center", md: "row between center" })}`}>
                <div >
                  <TeamMitglied image={berthold} rolle={"Architekt"} description={"Architekt DIPL. ING. FH. BY. AK. BDA."} hasExtraRole="Geschäfts führer" surname={"Bertold"} name={"Ziersch"}></TeamMitglied>
                </div>
                <div >
                  <TeamMitglied image={david} rolle={"Architekt"} description={"BY. AK. TUM B.A.M.A."} hasExtraRole="Geschäfts führer" surname={"David"} name={"Ziersch"}></TeamMitglied>
                </div>


                <div >
                  <TeamMitglied image={gundula} rolle={"Architektin"} description={"DIPL. ING. TUM BY. AK."} surname={"Gundula"} name={"Rathjen"}></TeamMitglied>
                </div>

              </div>
              <div className={`marginPlaceholder ${Container({ xs: "row center center", sm: "row center center", md: "row between center" })}`}>
                <div >
                  <TeamMitglied image={dora} rolle={"Architektin"} description={" B.A.M.A. "} surname={"Dora"} name={"Nagy"}></TeamMitglied>
                </div>
                <div >
                  <TeamMitglied image={günter} rolle={"Architekt"} description={"DIPL. ING. BY. AK. .m-architektur. de"} surname={"Günther"} name={"Möller"}></TeamMitglied>
                </div>
                <div >
                  <TeamMitglied image={kathrin} rolle={"Architektin"} description={"DIPL. ING. BY. AK. .m-architektur. de "} surname={"Kathrin"} name={"Möller"}></TeamMitglied>
                </div>
              </div>
              <div className={`marginPlaceholder ${Container({ xs: "row center center", sm: "row center center", md: "row between center" })}`}>
                <div >
                  <TeamMitglied image={buerofinal_img_2} rolle={""} description={""} name={""}></TeamMitglied>
                </div>
                <div >
                  <TeamMitglied image={martin} rolle={"Bauleiter"} description={"DIPL. ING."} surname={"Martin"} name={"Ernst"}></TeamMitglied>
                </div>
                <div >
                  <TeamMitglied image={buerofinal_img_1} rolle={""} description={""} name={""}></TeamMitglied>
                </div>

              </div>
            </div>

          </Page>
          <FooterPlanung></FooterPlanung>
        </div>
      </div>
    );
  }
}
