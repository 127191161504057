import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Projekte.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { TopbarPartner } from '../../directives/components/topbar/TopbarPartner';
import { FooterPartner } from '../../directives/components/FooterPartner';
import { TextboxBig } from '../../directives/components/util/TextboxBig';
import { Projekte_Widget } from '../../directives/components/util/Projekte_Widget';
import { Landing } from '../Landing';

// // //Akilindastraße
// import Akilindastraße_img_1 from './../../images/projects/Akilindastraße/_MMP3850.jpg';
// import Akilindastraße_img_2 from './../../images/projects/Akilindastraße/_MMP3810.jpg';
// import Akilindastraße_img_3 from './../../images/projects/Akilindastraße/_MMP3735.jpg';
// import Akilindastraße_img_4 from './../../images/projects/Akilindastraße/_MMP3757.jpg';
// import Akilindastraße_img_5 from './../../images/projects/Akilindastraße/_MMP3823.jpg';
// import Akilindastraße_img_6 from './../../images/projects/Akilindastraße/bea _MMP3783.jpg';
// import Akilindastraße_img_7 from './../../images/projects/Akilindastraße/bea _MMP3829.jpg';

//Bruderstraße
import bruderstr_img_1 from './../../images/projects/Bruderstraße/IMG_2376.jpg';
import bruderstr_img_2 from './../../images/projects/Bruderstraße/IMG_2457.jpg';
import bruderstr_img_3 from './../../images/projects/Bruderstraße/IMG_2680.jpg';
import bruderstr_img_4 from './../../images/projects/Bruderstraße/IMG_2681.jpg';
import bruderstr_img_5 from './../../images/projects/Bruderstraße/IMG_9874.jpg';
import bruderstr_img_6 from './../../images/projects/Bruderstraße/IMG_9880.jpg';

//Dreschstraße
import dreschstr_img_1 from './../../images/projects/dreschstraße/_MG_0517.jpg';
import dreschstr_img_2 from './../../images/projects/dreschstraße/_MG_0529.jpg';
import dreschstr_img_3 from './../../images/projects/dreschstraße/_MG_0557.jpg';
import dreschstr_img_4 from './../../images/projects/dreschstraße/_MG_0588.jpg';
import dreschstr_img_5 from './../../images/projects/dreschstraße/_MG_0633.jpg';
import dreschstr_img_6 from './../../images/projects/dreschstraße/_MG_0637.jpg';
import dreschstr_img_7 from './../../images/projects/dreschstraße/_MG_0657.jpg';
import dreschstr_img_8 from './../../images/projects/dreschstraße/_MG_0668.jpg';
import dreschstr_img_9 from './../../images/projects/dreschstraße/_MG_0695.jpg';
import dreschstr_img_11 from './../../images/projects/dreschstraße/_MG_0698.jpg';
import dreschstr_img_12 from './../../images/projects/dreschstraße/_MG_0712.jpg';
import dreschstr_img_13 from './../../images/projects/dreschstraße/_MG_0714.jpg';

//Haus1
import haus1_img_1 from './../../images/projects/haus1/_MMP3735.jpg';
import haus1_img_2 from './../../images/projects/haus1/_MMP3757.jpg';
import haus1_img_3 from './../../images/projects/haus1/_MMP3810.jpg';
import haus1_img_4 from './../../images/projects/haus1/_MMP3823.jpg';
import haus1_img_5 from './../../images/projects/haus1/_MMP3850.jpg';
import haus1_img_6 from './../../images/projects/haus1/bea _MMP3783.jpg';
import haus1_img_7 from './../../images/projects/haus1/bea _MMP3829.jpg';


//Haus2
import haus2_img_1 from './../../images/projects/haus2/DSC02636.jpg';
import haus2_img_2 from './../../images/projects/haus2/DSC02654.jpg';
import haus2_img_3 from './../../images/projects/haus2/DSC02658-Pano.jpg';

//haus3
import haus3_img_1 from './../../images/projects/haus3/DSC02720-Pano.jpg';
import haus3_img_2 from './../../images/projects/haus3/DSC02729.jpg';
import haus3_img_3 from './../../images/projects/haus3/DSC02732-Pano.jpg';
import haus3_img_4 from './../../images/projects/haus3/DSC02744-Pano.jpg';
import haus3_img_5 from './../../images/projects/haus3/DSC02756.jpg';
import haus3_img_6 from './../../images/projects/haus3/DSC02759.jpg';
import haus3_img_7 from './../../images/projects/haus3/DSC02774.jpg';
import haus3_img_8 from './../../images/projects/haus3/DSC02786-Pano-2.jpg';
import haus3_img_9 from './../../images/projects/haus3/DSC02802.jpg';

//haus4
import haus4_img_1 from './../../images/projects/haus4/DSC02908-Pano-3.jpg';
import haus4_img_2 from './../../images/projects/haus4/DSC02918-Pano-3.jpg';
import haus4_img_3 from './../../images/projects/haus4/DSC02933-Pano.jpg';
import haus4_img_4 from './../../images/projects/haus4/DSC02946-Pano.jpg';
import haus4_img_5 from './../../images/projects/haus4/DSC02975-Pano-2.jpg';
import haus4_img_6 from './../../images/projects/haus4/DSC02982.jpg';
import haus4_img_7 from './../../images/projects/haus4/DSC02987-Pano.jpg';
import haus4_img_8 from './../../images/projects/haus4/DSC02992.jpg';
import haus4_img_9 from './../../images/projects/haus4/DSC02999-Pano.jpg';

//haus5
import haus5_img_1 from './../../images/projects/haus5/DSC03008-Pano.jpg';
import haus5_img_2 from './../../images/projects/haus5/DSC03026-Pano.jpg';
import haus5_img_3 from './../../images/projects/haus5/DSC03042-Pano.jpg';
import haus5_img_4 from './../../images/projects/haus5/DSC03081-Pano.jpg';
import haus5_img_5 from './../../images/projects/haus5/DSC03089.jpg';


//kitzbühel
import kitzbuehel_img_1 from './../../images/projects/kitzbuehel/DSC03210-Pano-2.jpg';
import kitzbuehel_img_2 from './../../images/projects/kitzbuehel/DSC03228.jpg';
import kitzbuehel_img_3 from './../../images/projects/kitzbuehel/DSC03232.jpg';
import kitzbuehel_img_4 from './../../images/projects/kitzbuehel/DSC03243-Pano.jpg';
import kitzbuehel_img_5 from './../../images/projects/kitzbuehel/DSC03273-Pano-2.jpg';
import kitzbuehel_img_6 from './../../images/projects/kitzbuehel/DSC03284.jpg';
import kitzbuehel_img_7 from './../../images/projects/kitzbuehel/DSC03296-Pano.jpg';
import kitzbuehel_img_8 from './../../images/projects/kitzbuehel/DSC03317.jpg';
import kitzbuehel_img_9 from './../../images/projects/kitzbuehel/DSC03347-Pano.jpg';
import kitzbuehel_img_11 from './../../images/projects/kitzbuehel/DSC03353-Pano.jpg';
import kitzbuehel_img_12 from './../../images/projects/kitzbuehel/DSC03363.jpg';


//mitterwieserstraße
import mitterwieserstraße_img_1 from './../../images/projects/mitterwieserstraße/DSC03188-Pano.jpg';
import mitterwieserstraße_img_2 from './../../images/projects/mitterwieserstraße/DSC03194-Pano.jpg';
import mitterwieserstraße_img_3 from './../../images/projects/mitterwieserstraße/DSC03203.jpg';


//ICONS

//Bruderstraße
import bruderstr_img_1Icon from './../../images/projectsIcons/Bruderstraße/IMG_2376.jpg';
import bruderstr_img_2Icon from './../../images/projectsIcons/Bruderstraße/IMG_2457.jpg';
import bruderstr_img_3Icon from './../../images/projectsIcons/Bruderstraße/IMG_2680.jpg';
import bruderstr_img_4Icon from './../../images/projectsIcons/Bruderstraße/IMG_2681.jpg';
import bruderstr_img_5Icon from './../../images/projectsIcons/Bruderstraße/IMG_9874.jpg';
import bruderstr_img_6Icon from './../../images/projectsIcons/Bruderstraße/IMG_9880.jpg';

//Dreschstraße
import dreschstr_img_1Icon from './../../images/projectsIcons/dreschstraße/_MG_0517.jpg';
import dreschstr_img_2Icon from './../../images/projectsIcons/dreschstraße/_MG_0529.jpg';
import dreschstr_img_3Icon from './../../images/projectsIcons/dreschstraße/_MG_0557.jpg';
import dreschstr_img_4Icon from './../../images/projectsIcons/dreschstraße/_MG_0588.jpg';
import dreschstr_img_5Icon from './../../images/projectsIcons/dreschstraße/_MG_0633.jpg';
import dreschstr_img_6Icon from './../../images/projectsIcons/dreschstraße/_MG_0637.jpg';
import dreschstr_img_7Icon from './../../images/projectsIcons/dreschstraße/_MG_0657.jpg';
import dreschstr_img_8Icon from './../../images/projectsIcons/dreschstraße/_MG_0668.jpg';
import dreschstr_img_9Icon from './../../images/projectsIcons/dreschstraße/_MG_0695.jpg';
import dreschstr_img_11Icon from './../../images/projectsIcons/dreschstraße/_MG_0698.jpg';
import dreschstr_img_12Icon from './../../images/projectsIcons/dreschstraße/_MG_0712.jpg';
import dreschstr_img_13Icon from './../../images/projectsIcons/dreschstraße/_MG_0714.jpg';

//Haus1
import haus1_img_1Icon from './../../images/projectsIcons/haus1/_MMP3735.jpg';
import haus1_img_2Icon from './../../images/projectsIcons/haus1/_MMP3757.jpg';
import haus1_img_3Icon from './../../images/projectsIcons/haus1/_MMP3810.jpg';
import haus1_img_4Icon from './../../images/projectsIcons/haus1/_MMP3823.jpg';
import haus1_img_5Icon from './../../images/projectsIcons/haus1/_MMP3850.jpg';
import haus1_img_6Icon from './../../images/projectsIcons/haus1/bea _MMP3783.jpg';
import haus1_img_7Icon from './../../images/projectsIcons/haus1/bea _MMP3829.jpg';

//Haus2Icon
import haus2_img_1Icon from './../../images/projectsIcons/haus2/DSC02636.jpg';
import haus2_img_2Icon from './../../images/projectsIcons/haus2/DSC02654.jpg';
import haus2_img_3Icon from './../../images/projectsIcons/haus2/DSC02658-Pano.jpg';

//haus3Icon
import haus3_img_1Icon from './../../images/projectsIcons/haus3/DSC02720-Pano.jpg';
import haus3_img_2Icon from './../../images/projectsIcons/haus3/DSC02729.jpg';
import haus3_img_3Icon from './../../images/projectsIcons/haus3/DSC02732-Pano.jpg';
import haus3_img_4Icon from './../../images/projectsIcons/haus3/DSC02744-Pano.jpg';
import haus3_img_5Icon from './../../images/projectsIcons/haus3/DSC02756.jpg';
import haus3_img_6Icon from './../../images/projectsIcons/haus3/DSC02759.jpg';
import haus3_img_7Icon from './../../images/projectsIcons/haus3/DSC02774.jpg';
import haus3_img_8Icon from './../../images/projectsIcons/haus3/DSC02786-Pano-2.jpg';
import haus3_img_9Icon from './../../images/projectsIcons/haus3/DSC02802.jpg';

//haus4Icon
import haus4_img_1Icon from './../../images/projectsIcons/haus4/DSC02908-Pano-3.jpg';
import haus4_img_2Icon from './../../images/projectsIcons/haus4/DSC02918-Pano-3.jpg';
import haus4_img_3Icon from './../../images/projectsIcons/haus4/DSC02933-Pano.jpg';
import haus4_img_4Icon from './../../images/projectsIcons/haus4/DSC02946-Pano.jpg';
import haus4_img_5Icon from './../../images/projectsIcons/haus4/DSC02975-Pano-2.jpg';
import haus4_img_6Icon from './../../images/projectsIcons/haus4/DSC02982.jpg';
import haus4_img_7Icon from './../../images/projectsIcons/haus4/DSC02987-Pano.jpg';
import haus4_img_8Icon from './../../images/projectsIcons/haus4/DSC02992.jpg';
import haus4_img_9Icon from './../../images/projectsIcons/haus4/DSC02999-Pano.jpg';

//haus5Icon
import haus5_img_1Icon from './../../images/projectsIcons/haus5/DSC03008-Pano.jpg';
import haus5_img_2Icon from './../../images/projectsIcons/haus5/DSC03026-Pano.jpg';
import haus5_img_3Icon from './../../images/projectsIcons/haus5/DSC03042-Pano.jpg';
import haus5_img_4Icon from './../../images/projectsIcons/haus5/DSC03081-Pano.jpg';
import haus5_img_5Icon from './../../images/projectsIcons/haus5/DSC03089.jpg';


//kitzbühel
import kitzbuehel_img_1Icon from './../../images/projectsIcons/kitzbuehel/DSC03210-Pano-2.jpg';
import kitzbuehel_img_2Icon from './../../images/projectsIcons/kitzbuehel/DSC03228.jpg';
import kitzbuehel_img_3Icon from './../../images/projectsIcons/kitzbuehel/DSC03232.jpg';
import kitzbuehel_img_4Icon from './../../images/projectsIcons/kitzbuehel/DSC03243-Pano.jpg';
import kitzbuehel_img_5Icon from './../../images/projectsIcons/kitzbuehel/DSC03273-Pano-2.jpg';
import kitzbuehel_img_6Icon from './../../images/projectsIcons/kitzbuehel/DSC03284.jpg';
import kitzbuehel_img_7Icon from './../../images/projectsIcons/kitzbuehel/DSC03296-Pano.jpg';
import kitzbuehel_img_8Icon from './../../images/projectsIcons/kitzbuehel/DSC03317.jpg';
import kitzbuehel_img_9Icon from './../../images/projectsIcons/kitzbuehel/DSC03347-Pano.jpg';
import kitzbuehel_img_11Icon from './../../images/projectsIcons/kitzbuehel/DSC03353-Pano.jpg';
import kitzbuehel_img_12Icon from './../../images/projectsIcons/kitzbuehel/DSC03363.jpg';


//mitterwieserstraße
import mitterwieserstraße_img_1Icon from './../../images/projectsIcons/mitterwieserstraße/DSC03188-Pano.jpg';
import mitterwieserstraße_img_2Icon from './../../images/projectsIcons/mitterwieserstraße/DSC03194-Pano.jpg';
import mitterwieserstraße_img_3Icon from './../../images/projectsIcons/mitterwieserstraße/DSC03203.jpg';




function isElementInViewport(el: HTMLDivElement | null): boolean {
  if (el == null)
    return false;
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}
export default class Projekte extends React.Component<{

}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh", position: "relative" }}>
        <div>
          <Page>
            <TopbarPartner></TopbarPartner>
            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='Gräfelfing'
              Zeitraum='2019 - 2020'
              Typologie='Einfamilienhaus'
              reference={Landing.Projekt3}
              title="Haus3" textboxTitle="Beispieltext" textboxDescription="BeispieltextBeispieltextBeispieltextBeispieltextBeispieltextBeispieltextBeispieltextBeispieltext"
              projects={[
                {
                  images: [
                    { path: haus3_img_8, iconpath: haus3_img_8Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: haus3_img_6, iconpath: haus3_img_6Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus3_img_5, iconpath: haus3_img_5Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: haus3_img_1, iconpath: haus3_img_1Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus3_img_2, iconpath: haus3_img_2Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus3_img_3, iconpath: haus3_img_3Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus3_img_4, iconpath: haus3_img_4Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus3_img_7, iconpath: haus3_img_7Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus3_img_9, iconpath: haus3_img_9Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "In einem Vorort Münchens steht das Kaffeemühlenhaus aus den dreißiger Jahren. Nach Untersuchung der Bausubstanz wurde die Entscheidung getroffen, den Bestand zu erweitern. Die Doppelgarage aus Stahlbeton geht nahtlos in den Sockel des Anbaus über. Große Glaselemente öffnen das Erdgeschoss zu beiden Seiten. Ein sechs Meter langer Küchentresen verläuft längs durch den Raum und bildet das neue Zentrum des Hauses. Das Lichtband trennt das Obergeschoss von der Garagenwand, auf Stahlstützen wurde das Geschoss in reiner Holzkonstruktion errichtet. Das Bestandsgebäude wird entkernt und saniert.",
                  name: "Projektname - 1"
                }
              ]} />
            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='München'
              Zeitraum='2003 - 2004'
              Typologie='Einfamilienhaus'
              reference={Landing.Projekt3}
              title="Haus4" textboxTitle="Beispieltext" textboxDescription="Beispieltext"

              projects={[
                {
                  images: [
                    { iconpath: haus4_img_2Icon, path: haus4_img_2, name: "Projektbild_1", description: "Beschreibung 1" },
                    { iconpath: haus4_img_8Icon, path: haus4_img_8, name: "Projektbild_2", description: "Beschreibung 2" },
                    { iconpath: haus4_img_5Icon, path: haus4_img_5, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: haus4_img_4Icon, path: haus4_img_4, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: haus4_img_3Icon, path: haus4_img_3, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: haus4_img_6Icon, path: haus4_img_6, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: haus4_img_7Icon, path: haus4_img_7, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: haus4_img_1Icon, path: haus4_img_1, name: "Projektbild_3", description: "Beschreibung 3" },
                    { iconpath: haus4_img_9Icon, path: haus4_img_9, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Das Einfamilienhaus wurde für eine vierköpfige Familie gebaut. Die Südfassade ist weitesgehend verglast, verschiebbare Rahmen, besetzt mit Holzlamellen, können bei Bedarf die Sonneneinstrahlung reduzieren. Ähnlich einem Loft, ist das Erdgeschoss nur durch den Kamin und die Treppe zoniert, auf diese Weise wird die gesamte Länge des Gebäudes räumlich erfahrbar. Der Rest des Hauses ist räumlich unterteilt, das Obergeschoss bietet drei Schlafzimmer mit zwei Bädern. Treppe und Flur bilden die Achse des Hauses, die in der gesamten Länge über die Dachverglasung belichtet ist. Im Untergeschoss wurde ein Sport und Welness Bereich integriert, die rückwärtige Abgrabung versorgt das Untergeschoss mit Licht",
                  name: "Projektname - 1"
                }
              ]} />
            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='Kitzbühel'
              Zeitraum='2016'
              Typologie='Ferienhaus'
              reference={Landing.Projekt3}
              title="Kitzbühel" textboxTitle="Beispieltext" textboxDescription=""

              projects={[
                {
                  images: [
                    { path: kitzbuehel_img_1, iconpath: kitzbuehel_img_1Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: kitzbuehel_img_5, iconpath: kitzbuehel_img_5Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: kitzbuehel_img_8, iconpath: kitzbuehel_img_8Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_4, iconpath: kitzbuehel_img_4Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_2, iconpath: kitzbuehel_img_2Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_6, iconpath: kitzbuehel_img_6Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_7, iconpath: kitzbuehel_img_7Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_3, iconpath: kitzbuehel_img_3Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_9, iconpath: kitzbuehel_img_9Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_11, iconpath: kitzbuehel_img_11Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: kitzbuehel_img_12, iconpath: kitzbuehel_img_12Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Nach dem Abriss des Bestandsgebäudes aus den siebziger Jahren, wurde die Baulücke in der Kitzbühler Innenstadt, orientiert an benachbartem Bestand, neu bebaut. Das Baurecht ermöglichte im hinteren Bereich des Grundstücks mehr Bauhöhe, was im Entwurf zu einem Splitlevel führte. Konzepiert als Ferienhaus, teilt es sich funktional in die drei Geschosse Gäste-, Kinder- und Elternetage auf. Darüber hinaus sind im Untergeschoss Sport- und Wellness Bereiche integriert. Die Gestaltung der Fassade orientiert sich an den Proportionen der historischen, lokalen Bauweisen, große Glaselemente werden durch Lamellenstrukturen unauffällig integriert. Sorgfalt im Detail und handwerkliche Qualität prägen das Erscheinungsbild des Gebäudes.",
                  name: "Projektname - 1"
                }
              ]} />
            {/* <Projekte_Widget
              imageNumber={3}
              reference={Landing.Projekt3}
              title="Akilindastraße" textboxTitle="Beispieltext" textboxDescription="Beispieltext"

              projects={[
                {
                  images: [
                    { path: Akilindastraße_img_1, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: Akilindastraße_img_2, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: Akilindastraße_img_3, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: Akilindastraße_img_4, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: Akilindastraße_img_5, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: Akilindastraße_img_6, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: Akilindastraße_img_7, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Akilindastraße",
                  name: "Projektname - 1"
                }
              ]} /> */}
            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='Gräfelfing'
              Zeitraum='2016 - 2017'
              Typologie='Einfamilienhaus'
              reference={Landing.Projekt3}
              title="Haus1" textboxTitle="Beispieltext" textboxDescription="Beispieltext"

              projects={[
                {
                  images: [
                    { path: haus1_img_5, iconpath: haus1_img_5Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: haus1_img_3, iconpath: haus1_img_3Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: haus1_img_1, iconpath: haus1_img_1Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus1_img_4, iconpath: haus1_img_4Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus1_img_2, iconpath: haus1_img_2Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus1_img_6, iconpath: haus1_img_6Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus1_img_7, iconpath: haus1_img_7Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Nach dem Abriss des Bestandsgebäudes aus den siebziger Jahren, wurde das 1400 Quadratmeter große Grundstück mit zwei Einfamilienhäusern bebaut. Ziel des Entwurfs war trotz strenger baurechtlicher Auflagen maximale Großzügigkeit im Inneren des Gebäudes und dem Einbezug des Außenraums. Beide Gebäude werden durch eine Einfahrt mit Doppelgarage und direktem Hauszugang erschlossen. Um einen funktionalen Gebäudekern ist das Wohnzimmer angeordnet, über zwei Achsen sind über raumhohe Verglasungen Bezüge in das umliegende Grün möglich. Das Obergeschoss bietet zwei Schlafzimmer mit zwei Bädern. Im Dachgeschoss ist eine weitere Suite untergebracht.",
                  name: "Projektname - 1"
                }
              ]} />
            <Projekte_Widget
              imageNumber={3}

              reference={Landing.Projekt3}
              title="Dreschstraße" textboxTitle="Beispieltext" textboxDescription="Beispieltext"
              Bauherr='Privat'
              Standort='München'
              Zeitraum='2008'
              Typologie='Einfamilienhaus'
              projects={[
                {
                  images: [
                    { path: dreschstr_img_1, iconpath: dreschstr_img_1Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: dreschstr_img_7, iconpath: dreschstr_img_7Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: dreschstr_img_11, iconpath: dreschstr_img_11Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: dreschstr_img_4, iconpath: dreschstr_img_4Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: dreschstr_img_5, iconpath: dreschstr_img_5Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: dreschstr_img_6, iconpath: dreschstr_img_6Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: dreschstr_img_2, iconpath: dreschstr_img_2Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: dreschstr_img_8, iconpath: dreschstr_img_8Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: dreschstr_img_9, iconpath: dreschstr_img_9Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: dreschstr_img_3, iconpath: dreschstr_img_3Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: dreschstr_img_12, iconpath: dreschstr_img_12Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: dreschstr_img_13, iconpath: dreschstr_img_13Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Das Einfamilienhaus wurde für eine vierköpfige Familie in der Innenstadt Münchens gebaut. Dichte Nachbarbebauung und das betriebsame städtische Umfeld erzeugten im Entwurf einen dreisit introvertierten Baukörper, der sich in Grundstückachse nach Süden öffnet. Die vorgehängten pigmentierten Betonplatten bringen die Massivität des Stahlbetonbaus zum Ausdruck. Leichte Trockenbauwände sind mit Holz verschalt. Im Erdgeschoss erstreckt sich das Wohnzimmer über die gesamte Gebäudelänge, über raumhohe Fenster wird der Garten erfahrbar. Die beiden Obergeschosse bilden in der Raumaufteilung die Bedürfnisse und Anforderungen der Bewohner ab.",
                  name: "Projektname - 1"
                }
              ]} />


            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='München'
              Zeitraum='2013'
              Typologie='Einfamilienhaus'
              reference={Landing.Projekt3}
              title="Haus5" textboxTitle="Beispieltext" textboxDescription="Beispieltext"

              projects={[
                {
                  images: [
                    { path: haus5_img_5, iconpath: haus5_img_5Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: haus5_img_4, iconpath: haus5_img_4Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: haus5_img_2, iconpath: haus5_img_2Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus5_img_1, iconpath: haus5_img_1Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: haus5_img_3, iconpath: haus5_img_3Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Das Einfamilienhaus aus den dreißiger Jahren wurde im Erdgeschoss mit drei großen Durchbrüchen durch die Hausaußenwand geöffnet. Zwei Stufen führen von dem Hochparterre in den ebenerdigen Anbau. Der Esstisch und die Küche ist in Form einer begehbaren Kochinsel in den Anbau ausgelagert und das Erdgeschoss auf diese Weise großzügig. Ein Lichtband in Länge des Anbaus belichtet den Altbau trotz der Gebäudetiefe. Der gesamte Anbau ist in Holzbauweise errichtet und innen mit moderner weißer Verschalung, außen mit Holzlamellen verkleidet. Die Ziegel der Außenwände des Altbaus sind freigelegt, weiß geschlämmt, werden alte konstruktive Strukturen des Bestands in die lichte Atmosphäre des Neubaus integriert."
                  , name: "Projektname - 1"
                }
              ]} />

            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='München'
              Zeitraum='2007'
              Typologie='Einfamilienhaus'
              reference={Landing.Projekt3}
              title="Haus2" textboxTitle="Beispieltext" textboxDescription="Beispieltext"

              projects={[
                {
                  images: [
                    { path: haus2_img_1, iconpath: haus2_img_1Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: haus2_img_3, iconpath: haus2_img_3Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: haus2_img_2, iconpath: haus2_img_2Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description:
                    "Das Einfamilienhaus aus der Jahrhundertwende wurde im Innenraum entkernt. Im Rahmen der denkmalpflegerischen Möglichkeiten und in Einbezug der verbliebenen historischen Elemente wurde das Gebäude modernisiert. Der Anbau erweitert das Erdgeschoss und schafft großzügige, verglaste Räume, die den Garten erfahrbar machen. In der Gestaltung nimmt der Anbau die Proportionen und Formensprache der Bauzeit des Hauses an, zugleich sind moderne, lichte Räume entstanden. Unterkellert, sind im Untergeschoss zwei Souterrain Wohnungen untergebracht, die über Terrassierungen belichtet werden. Eine neue Treppenanlage erschließt den Dachspitz und schafft eine vierte Etage.",
                  name: "Projektname - 1"
                }
              ]} />

            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='München'
              Zeitraum='2013'
              Typologie='Mehrfamilienhaus'
              reference={Landing.Projekt3}
              title="Mitterwieserstraße" textboxTitle="Beispieltext" textboxDescription="Beispieltext"

              projects={[
                {
                  images: [
                    { path: mitterwieserstraße_img_1, iconpath: mitterwieserstraße_img_1Icon, name: "Projektbild_1", description: "Beschreibung 1" },
                    { path: mitterwieserstraße_img_3, iconpath: mitterwieserstraße_img_3Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: mitterwieserstraße_img_2, iconpath: mitterwieserstraße_img_2Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Das schwabinger Mehrfamilienhaus aus den sechziger Jahren wurde energetisch und brandschutztechnisch saniert. Die Außenwände wurden mit einem Vollwärmeschutz versehen, über die Funktionalität hinaus, entstand dabei durch Ornamentik, Faschen und Sockelausbildung eine neue Fassade mit der Anmutung eines Altbaus. Das Treppenhaus wurde saniert und ein neues Beleuchtungskonzept integriert, der Haupteingang und alle Wohnungseingänge neu inszeniert.",
                  name: "Projektname - 1"
                }
              ]} />
            <Projekte_Widget
              imageNumber={3}
              Bauherr='Privat'
              Standort='München'
              Zeitraum='2010'
              Typologie='Mehrfamilienhaus'
              reference={Landing.Projekt3}
              title="Bruderstraße" textboxTitle="Beispieltext" textboxDescription="Beispieltext"
              projects={[
                {
                  images: [
                    { path: bruderstr_img_5, iconpath: bruderstr_img_5Icon, name: "Projektbild_1", description: "Beschreibung 35" },
                    { path: bruderstr_img_4, iconpath: bruderstr_img_4Icon, name: "Projektbild_2", description: "Beschreibung 2" },
                    { path: bruderstr_img_3, iconpath: bruderstr_img_3Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: bruderstr_img_2, iconpath: bruderstr_img_2Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: bruderstr_img_1, iconpath: bruderstr_img_1Icon, name: "Projektbild_3", description: "Beschreibung 3" },
                    { path: bruderstr_img_6, iconpath: bruderstr_img_6Icon, name: "Projektbild_3", description: "Beschreibung 3" }],

                  description: "Das Mehrfamilienhaus im Lehel wurde über Jahre hinweg saniert. Dabei stand der Denkmalschutz im Mittelpunkt, in allen Bereichen, wurden historische Elemente erhalten und saniert. Die Sanierung reichen vom Untergeschoss bis zum Dachausbau durch alle Geschosse, die Haustechnik wurde für das Ensemble neu angelegt.",
                  name: "Projektname - 1"
                }
              ]} />

          </Page>
          <FooterPartner></FooterPartner>
        </div>
      </div>
    );
  }
}
