import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Profil.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { TopbarPlanung } from '../../directives/components/topbar/TopbarPlanung';
import { FooterPlanung } from '../../directives/components/FooterPlanung';
import { TextboxBig } from '../../directives/components/util/TextboxBig';

export default class ProfilPlanung extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page>
            <TopbarPlanung></TopbarPlanung>
            <div className={`${Container("column start stretch")} `}>
              <TextboxBig
                title={'Vorstellung:'}
                decsription={
                  <>
                    Die Ziersch Planungs GmbH greift auf die Erfahrung des Teams des Architekturbüros Bertold Ziersch zurück. Die
                    langjährige Zusammenarbeit mit versierten und erfahrenen Fachplanern gibt die Garantie mit einem eingespielten Team
                    zu arbeiten.</>
                } />
              <TextboxBig
                title={'Kompetenz:'}
                decsription={
                  <>
                    Die Ziersch Planungs GmbH hat ihren Fokus auf Bauten für Pflege und Gesundheit gerichtet. Das Architekturbüro Bertold
                    Ziersch hat das Pflegeheim der Stiftung Ingenium in Ingolstadt erstellt. Dieses Pflegeheim wurde mit dem international
                    ausgelobten 1. Preis der Gradmannstiftung ausgezeichnet.
                    Während der Planungsphase wurde deutlich wie hilfreich eine reibungsfreie Zusammenarbeit aller Projektbeteiligten bei
                    der Gebäudeentwicklung ist und wo Optimierungspotentiale in diesem Prozess stecken. Die Planungs GmbH wurde
                    ergänzend zum Architekturbüro Bertold Ziersch gegründet, um Generalplanerleistungen anbieten zu können .
                  </>
                } />
              <TextboxBig
                hasNoMarginPlaceholder={true}
                title={'Leistung:'}
                decsription={
                  <>
                    <span>
                      Das Leistungsspektrum umfasst alle notwendigen
                      Planungsleistungen. Der Bauherr erhält mit Vertragsunterzeichnung
                      alle Fachplanerleistungen, die zur Realisierung eines Bauvorhabens
                      notwendig sind, aus einer Hand d.h. die Notwendigkeit einer
                      zusätzlichen Projektsteuerung entfällt, da weder
                      Schnittstellenprobleme entstehen können, noch der interne
                      Informationsaustausch strukturiert werden muss. Alle
                      Leistungsphasen von 1-9 werden hierbei abgedeckt.
                      Die Planungen der Gebäudetechnik und Statik, alle Leistungen im
                      Rahmen von Brandschutz und Energieoptimierung sowie die Planung
                      der Außenanlagen sind darin enthalten. Für die Bauleitung stehen
                      erfahrene Bauingenieure aus dem Planerteam zur Verfügung. Die
                      Leistungen werden durch eine projektbezogene Versicherung
                      abgedeckt und enden mit der mängelfreien Übergabe des
                      Bauvorhabens an den Bauherren.
                    </span>
                    {/* <span>Das Leistungsspektrum umfasst alle notwendigen Planungsleistungen. Der Bauherr erhält mit Vertragsunterzeichnung
                      alle Fachplanerleistungen , die zur Realisierung eines Bauvorhabens notwendig sind , aus einer Hand d.h. die
                      Notwendigkeit einer zusätzlichen Projektsteuerung entfällt, da weder Schnittstellenprobleme entstehen können, noch
                      der interne Informationsaustausch strukturiert werden muss.
                      Es werden die Leistungen abgedeckt zur Erstellung von:
                      <li>Flächennutzungsplänen</li>
                      <li>Bebauungsplänen</li>
                      <li>Entwurfs- , Eingabe- Detail- und Werkplänen</li>
                      <li>Ausschreibung</li>
                      <li>Vergaben und</li>
                      <li>Abrechnung</li>
                      Die Planungen der Gebäudetechnik und Statik , alle Leistungen im Rahmen von Brandschutz und Energieoptimierung
                      sowie die Planung der Außenanlagen sind darin enthalten . Für die Bauleitung stehen erfahrene Bauingenieure aus dem
                      Planerteam zur Verfügung. Die Leistungen werden durch eine projektbezogene Versicherung abgedeckt und enden mit
                      der mängelfreien Übergabe des Bauvorhabens an den Bauherren .</span> */}
                  </>
                } />
            </div>
          </Page>
          <FooterPlanung></FooterPlanung>
        </div>
      </div>
    );
  }
}
