import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Landing } from './pages/Landing';
import * as serviceWorker from './serviceWorker';
import { TopbarPartner } from './directives/components/topbar/TopbarPartner';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Partnerschaften from './pages/Partnerschaften';
import Profil from './pages/Partnerschaften/Profil';
import Archiv from './pages/Partnerschaften/ArchivPartner';
import Planungsgesellschaft from './pages/Planungsgesellschaft';
import Aktuell from './pages/Partnerschaften/Aktuell';
import Projekte from './pages/Partnerschaften/Projekte';
import Team from './pages/Partnerschaften/TeamPartner';
import Kontakt from './pages/Partnerschaften/Kontakt';
import KontaktPlanung from './pages/Planungsgesellschaft/KontaktPlanung';
import AktuellPlanung from './pages/Planungsgesellschaft/AktuellPlanung';
import ProfilPlanung from './pages/Planungsgesellschaft/ProfilPlanung';
import ProjektePlanung from './pages/Planungsgesellschaft/ProjektePlanung';
import ArchivPlanung from './pages/Planungsgesellschaft/ArchivPlanung';
import Datenschutz from './pages/Datenschutz';

import ImpressumPlanung from './pages/Planungsgesellschaft/ImpressumPlanung';
import ImpressumPartner from './pages/Partnerschaften/ImpressumPartner';
import DatenschutzPlanung from './pages/Planungsgesellschaft/DatenschutzPlanung';
import DatenschutzPartner from './pages/Partnerschaften/DatenschutzPartner';
import TeamPartner from './pages/Partnerschaften/TeamPartner';
import TeamPlanung from './pages/Planungsgesellschaft/TeamPlanung';
let routes = (
  <Router>
    <Switch>
      <Route exact path="/">
        <Landing />
      </Route>
      {/* <Route exact path="/Partnerschaften">
         <Partnerschaften />
       </Route>
       <Route exact path="/Planungsgesellschaft">
         <Planungsgesellschaft />
       </Route> */}

      <Route path="/Partnerschaften/Aktuell">
        <Aktuell />
      </Route>
      <Route path="/Partnerschaften/Profil">
        <Profil />
      </Route>
      <Route path="/Partnerschaften/Projekte">
        <Projekte />
      </Route>
      <Route path="/Partnerschaften/Team">
        <TeamPartner />
      </Route>
      <Route path="/Partnerschaften/Archiv">
        <Archiv />
      </Route>
      <Route path="/Partnerschaften/Kontakt">
        <Kontakt />
      </Route>
      <Route path="/Partnerschaften/Impressum">
        <ImpressumPartner />
      </Route>

      <Route path="/Partnerschaften/Datenschutz">
        <DatenschutzPartner />
      </Route>
      <Route path="/Planungsgesellschaft/Datenschutz">
        <DatenschutzPlanung />
      </Route>
      <Route path="/Planungsgesellschaft/Aktuell">
        <AktuellPlanung />
      </Route>
      <Route path="/Planungsgesellschaft/Profil">
        <ProfilPlanung />
      </Route>
      <Route path="/Planungsgesellschaft/Projekte">
        <ProjektePlanung />
      </Route>
      <Route path="/Planungsgesellschaft/Team">
        <TeamPlanung />
      </Route>
      <Route path="/Planungsgesellschaft/Archiv">
        <ArchivPlanung />
      </Route>
      <Route path="/Planungsgesellschaft/Kontakt">
        <KontaktPlanung />
      </Route>
      <Route path="/Planungsgesellschaft/Impressum">
        <ImpressumPlanung />
      </Route>

      <Route>
        <Landing />
      </Route>
    </Switch>
  </Router>
);


ReactDOM.render(
  <React.StrictMode>
    {routes}
    {/*  
        <Footer />
*/}

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();





