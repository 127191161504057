import { Container, Flex } from "@quesstor/react-flex-layout";
import React, { Ref } from "react";
import AwesomeSlider from "react-awesome-slider";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "./Icon";
import { Textbox } from "./Textbox";
import "./Projekte_Widget.css";
import { ProjectButtonDesign1 } from "./ProjectButtonDesign1";


export class Projekte_Widget extends React.Component<{
    reference: Ref<HTMLDivElement>;
    title?: string;
    textboxTitle?: string;
    textboxDescription?: string;
    Bauherr?: string;
    Standort?: string;
    Zeitraum?: string;
    Typologie?: string;
    Auftrag?: string;

    link?: string;
    imageNumber: number;
    projects?: { name?: string, description?: string, images?: { iconpath?: string, path?: string, name?: string, description?: string }[] }[];
}> {
    state = { activeIndex: 0, showProjectPopup: false, handleHover: false, inViewport: false };
    render() {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // for smoothly scrolling
            });
        };
        return (
            <div className="partentObject" style={{}} >
                <div ref={this.props.reference}>
                    {/* <div className={`${Container("row around stretch")} `}>
                    <div className={`${Container({ xs: "row center stretch" , sm: "row start stretch" })} ${Flex({ xs: { flex: "1" } })}`}>
                        <img src={project_1_img_1} className="" alt="projektimage" style={{ objectFit: "cover", height: 220, width: 300 }} />
                    </div>
                    <div className={`${Container({ xs: "row center stretch" , sm: "row start stretch" })} ${Flex({ xs: { flex: "1" } })}`}>
                        <img src={project_1_img_1} className="" alt="projektimage" style={{ objectFit: "cover", height: 220, width: 300 }} />
                    </div>
                    <div className={`${Container({ xs: "row center stretch" , sm: "row start stretch" })} ${Flex({ xs: { flex: "1" } })}`}>
                        <img src={project_1_img_1} className="" alt="projektimage" style={{ objectFit: "cover", height: 220, width: 300 }} />
                    </div>
                </div> */}
                    <div  >
                        {this.props.projects && this.props.projects.map((project, l) =>
                            <div onClick={scrollToTop} key={l} className={`${Container({ xs: "row between center", md: "row between center" })} `}
                            >
                                {/* todo set Index to k here so when image is clicked right image is opened in carousel */}
                                {project.images && project.images.slice(0, this.props.imageNumber).map((image, k) =>
                                    <div onClick={() => this.setState({ activeIndex: k, showProjectPopup: !this.state.showProjectPopup })} key={k}
                                        className={`img-hover-zoom projectImage ${Container({ xs: "column between center" })} `}
                                        style={{ cursor: "pointer" }}>
                                        <img className={"projectImageSize"}
                                            style={{ objectFit: "contain", alignSelf: k % 3 == 0 ? "flex-start" : (k % 3 == 1 ? "flex-center" : "flex-end"), padding: k % 3 == 0 ? "8px 8px 8px 0" : (k % 3 == 1 ? "8px 4px" : "8px 0 8px 8px") }}
                                            src={image.iconpath}
                                            alt="Image Loading"
                                        />
                                        {/* <div style={{color:"red", fontSize:45,position:"absolute", top: "50%", right:"50%",     transform: "translate(-50%, -50%)"}}>                                            
                                            {l}
                                        </div> */}
                                    </div>
                                )}

                                {this.state.showProjectPopup &&
                                    <>
                                        <div className={`${Container({ xs: "column center center" })}`} onClick={() => this.setState({ showProjectPopup: !this.state.showProjectPopup })}
                                            style={{ height: "100%", flexWrap: 'nowrap', display: "flex", alignItems: "center", justifyContent: "start", width: "100vw", position: "absolute", top: 0, left: 0, background: "rgba(255, 255, 255, 0.84)", zIndex: 1 }}>
                                            <div onClick={e => e.stopPropagation()} >
                                                <div onClick={() => this.setState({ showProjectPopup: !this.state.showProjectPopup })} className={`${Container({ xs: "row end end" })} `}
                                                    style={{ position: "absolute", top: 0, right: "50px", cursor: "pointer", zIndex: 15 }}>
                                                    <span style={{ textAlign: "center", color: "#5f5f5f", fontSize: 65, fontWeight: "bold" }}>&times;</span>
                                                </div>
                                                <Carousel touch={false} activeIndex={this.state.activeIndex} onSelect={v => this.setState({ activeIndex: v })}
                                                    prevIcon={<img src={"/images/icons/selfmade/arrowL.png"} style={{ position: "relative", top: 45, width: "25px", height: "38px" }} aria-hidden="true" className="carousel-control-prev-icon" />}
                                                    nextIcon={<img src={"/images/icons/selfmade/arrowR.png"} style={{ position: "relative", top: 45, width: "25px", height: "38px" }} aria-hidden="true" className="carousel-control-next-icon" />}
                                                    interval={null}
                                                >
                                                    {project.images && project.images.map((image, x) =>
                                                        <Carousel.Item key={x} style={{}} >
                                                            <div className={` ${Container({ xs: "column center center" })} `} style={{}}>
                                                                <img className={`carouselPadding`}
                                                                    style={{ objectFit: "contain" }}
                                                                    src={image.path}
                                                                    alt="Image Loading"
                                                                />

                                                                {/* <Carousel.Caption className="widgetText" style={{ padding: 18, position: "relative", left: 0, color: "grey", width: "100%" }} >
                                                                    <div className={` ${Container("row between stretch")} `} >
                                                                        <div>Bauherr:</div>
                                                                        <div>{this.props.Bauherr}</div>
                                                                    </div>
                                                                    <div className={` ${Container("row between stretch")} `} >
                                                                        <div>Standort:</div>
                                                                        <div>{this.props.Standort}</div>
                                                                    </div>
                                                                    <div className={` ${Container("row between stretch")} `} >
                                                                        <div>Zeitraum:</div>
                                                                        <div>{this.props.Zeitraum}</div>
                                                                    </div>
                                                                    <div className={` ${Container("row between stretch")} `} >
                                                                        <div>Typologie:</div>
                                                                        <div>{this.props.Typologie}</div>
                                                                    </div>
                                                                    <span style={{}}>{project.description}</span>
                                                                </Carousel.Caption> */}
                                                            </div>
                                                        </Carousel.Item>
                                                    )}
                                                </Carousel>
                                            </div>
                                            <div className={` ${Container("column center stretch")} widgetText`} style={{}} >
                                                <div className={` ${Container("row between stretch")} `} >
                                                    <div>Bauherr:</div>
                                                    <div>{this.props.Bauherr}</div>
                                                </div>
                                                <div className={` ${Container("row between stretch")} `} >
                                                    <div>Standort:</div>
                                                    <div>{this.props.Standort}</div>
                                                </div>
                                                <div className={` ${Container("row between stretch")} `} >
                                                    <div>Zeitraum:</div>
                                                    <div>{this.props.Zeitraum}</div>
                                                </div>
                                                <div className={` ${Container("row between stretch")} `} >
                                                    <div>Typologie:</div>
                                                    <div>{this.props.Typologie}</div>
                                                </div>
                                                {this.props.Auftrag ?
                                                    <div className={` ${Container("row between stretch")} `} >
                                                        <div>Auftrag:</div>
                                                        <div>{this.props.Auftrag}</div>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                <span style={{ marginTop: 18 }}>{project.description}</span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div >

        )
    }
}
//     render() {
//         return (
//             <div>
//
//             </div>
//         )
//     }
// }
