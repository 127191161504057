import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Aktuell.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { TopbarPartner } from '../../directives/components/topbar/TopbarPartner';
import { FooterPartner } from '../../directives/components/FooterPartner';
import { TextboxBig } from '../../directives/components/util/TextboxBig';
import Aktuelles from '../../directives/components/util/Aktuelles';

import Kaulbachstr1 from './../../images/projects/Aktuell/IMG_8925.jpeg';
import Kaulbachstr2 from './../../images/projects/Aktuell/IMG_8921.jpeg';
import Graef1 from './../../images/projects/Aktuell/MFHsSchmidbauerstraßeCam01_P03.jpg';
import Graef2 from './../../images/projects/Aktuell/MFHsSchmidbauerstraßeCam02_P03.jpg';
import NachlassGericht1 from './../../images/projects/Aktuell/IMG_8389.jpeg';
import NachlassGericht2 from './../../images/projects/Aktuell/IMG_8370.jpeg';

export default class Aktuell extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page>
            <TopbarPartner></TopbarPartner>
            <div className={`${Container("column start stretch")} `}>
              <Aktuelles
                header="T ü r k e n s t r a ß e M a x v o r s t a d t 2 0 2 2"
                text="Entkernung und Umgestaltung der Etage eines denkmalgeschützten Mehrfamilienhauses"
              >
              </Aktuelles>
              <Aktuelles
                header="K a u l b a c h s t r a ß e S c h w a b i n g 2 0 2 1 - 2 2"
                text="Entkernung und Umgestaltung mehrer Etagen Mehrfamilienhauses"
                image={Kaulbachstr1}
                image2={Kaulbachstr2}
              >
              </Aktuelles>
              <Aktuelles
                header="A r t u r - K u t s c h e r - P l a t z S c h w a b i n g 2 0 2 2"
                text="Innenausbau eines Penthouse im achten Geschoss eines Hochhauses"
              >
              </Aktuelles>
              <Aktuelles
                header="B r u d e r s t r a ß e L e h e l"
                text="Entkernung und Umgestaltung einer Wohnung"
              >
              </Aktuelles>
              <Aktuelles
                header="G r ä f e l f i n g 2 0 2 1"
                text="Konzeptionierung der Sanierung der ehemaligen Brauakademie Dömens zu"
                image={Graef1}
                image2={Graef2}
              >
              </Aktuelles>
              <Aktuelles
                header="H a b e n s c h a d e n s t r a ß e P u l l a c h 2 0 2 0 - 2 2"
                text="Restaurierung einer denkmalge- schützten Jugendstilvilla am Isarhang"
              >
              </Aktuelles>
              <Aktuelles
                header="H i l l e r n s t r a ß e P a s i n g 2 0 2 0 - 2 2"
                text="Sanierung eines denkmalge- schützten Mehrfamilienhauses in allen Geschossen mit Ausbau des Dachgeschoss"
              >
              </Aktuelles>
              <Aktuelles
                header="N a c h l a s s g e r i c h t F ü r s t e n f e l d b r u c k 2 0 1 9 - 2 1"
                text="Sanierung des Gerichtsgebäudes mit dem Hochbauamt Freising"
                image={NachlassGericht1}
                image2={NachlassGericht2}
              >
              </Aktuelles><Aktuelles
                header="I r m i n m f r i e d s t r a ß e G r ä f e l f i n g 2 0 2 0 - 2 1"
                text="Sanierung eines Gartenhauses mit Neugestaltung der Gartenanlage"
              >
              </Aktuelles>

              {/* <TextboxBig spec={true}
            title={'Vorstellung'} 
            decsription={
                <>
                  Die Ziersch Planungs GmbH greift auf die Erfahrung des Teams des Architekturbüros Bertold Ziersch zurück. Die
                  langjährige Zusammenarbeit mit versierten und erfahrenen Fachplanern gibt die Garantie mit einem eingespielten Team
                  zu arbeiten.
                </>
                } />
            <TextboxBig spec={true}
            title={'Kompetenz'} 
            decsription={
                <>
                  Die ziersch planungs gmbh hat ihren Fokus auf Bauten für Pflege und Gesundheit gerichtet. Das Architekturbüro Bertold
                  Ziersch hat das Pflegeheim der Stiftung Ingenium in Ingolstadt erstellt. Dieses Pflegeheim wurde mit dem international
                  ausgelobten 1. Preis der Gradmannstiftung ausgezeichnet.<br/>
                  Während der Planungsphase wurde deutlich wie hilfreich eine reibungsfreie Zusammenarbeit aller Projektbeteiligten bei
                  der Gebäudeentwicklung ist und wo Optimierungspotentiale in diesem Prozess stecken. Die Planungs GmbH wurde
                  ergänzend zum Architekturbüro Bertold Ziersch gegründet, um Generalplanerleistungen anbieten zu können.
                </>
                } />
            <TextboxBig spec={true}
            title={'Leistung'} 
            decsription={
                <>
                  Das Leistungsspektrum umfasst alle notwendigen Planungsleistungen. Der Bauherr erhält mit Vertragsunterzeichnung
                  alle Fachplanerleistungen, die zur Realisierung eines Bauvorhabens notwendig sind , aus einer Hand d.h. die
                  Notwendigkeit einer zusätzlichen Projektsteuerung entfällt, da weder Schnittstellenprobleme entstehen können, noch
                  der interne Informationsaustausch strukturiert werden muss.<br/>
                  Es werden die Leistungen abgedeckt zur Erstellung von:
                  <li>
                  Flächennutzungsplänen
                  </li><li>
                  Bebauungsplänen
                  </li><li>
                  Entwurfs- , Eingabe- Detail- und Werkplänen
                  </li><li>
                  Ausschreibung
                  </li><li>
                  Vergaben und
                  </li><li>
                  Abrechnung
                  </li>
                  Die Planungen der Gebäudetechnik und Statik , alle Leistungen im Rahmen von Brandschutz und Energieoptimierung
                  sowie die Planung der Außenanlagen sind darin enthalten . Für die Bauleitung stehen erfahrene Bauingenieure aus dem
                  Planerteam zur Verfügung. Die Leistungen werden durch eine projektbezogene Versicherung abgedeckt und enden mit
                  der mängelfreien Übergabe des Bauvorhabens an den Bauherren.       
                </>
                } /> */}
            </div>
          </Page>
          <FooterPartner></FooterPartner>
        </div>
      </div>
    );
  }
}
