import { Container, hide } from "@quesstor/react-flex-layout";
import React from "react";


export default class Aktuelles extends React.Component<{
    image?: string;
    image2?: string;

    header?: string;
    text?: string;
}> {
    render() {
        return (
            <div className={`${Container("column start stretch")} innerText`} style={{ margin: "24px 0", fontSize: "2.3vh", fontStyle: "italic", color: "#adadad", position: 'relative' }}>
                <div style={{}}>{this.props.header}</div>
                <div style={{ fontSize: "2.4vh" }}>{this.props.text}</div>
                <div className={`${Container("row start stretch")} innerText`} style={{}}>
                    {this.props.image ? <img src={this.props.image} className="App-logo" alt="logo" style={{ padding: "8px 8px 0 0", objectFit: "contain", height: this.props.image2 ? 160 : 240, }} /> : <></>}
                    {this.props.image2 ? <img src={this.props.image2} className="App-logo" alt="logo" style={{ padding: "8px 8px 0 0", objectFit: "contain", height: 160 }} /> : <></>}
                </div >
            </div >
        )
    }
} 
