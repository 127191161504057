import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Profil.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { FooterPartner } from '../../directives/components/FooterPartner';
import { TextboxBig } from '../../directives/components/util/TextboxBig';
import { TopbarPartner } from '../../directives/components/topbar/TopbarPartner';

export default class Profil extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page>
            <TopbarPartner></TopbarPartner>
            <div className={`${Container("column start stretch")} `}>
              <TextboxBig
                title={'Vorstellung:'}
                decsription={
                  <>
                    Mit Vater und Sohn schließen sich zwei Generationen zusammen. Ausbildungsschwerpunkt in der Fachhochschule München,der TechnischenUniversität München und der Akademie der Bildenen Künste München, liegen Schwerpunkte
                    im Bereich der Konstruktion / Bauphysik so wie der baukünstlerischen Gesamtbetrachtung.
                  </>
                } />
              <TextboxBig
                title={'Kompetenz:'}
                decsription={
                  <>
                    Das Büro arbeitet seit drei Jahrzehnten für private Bauherren in den Bereichen Neubau, Modernisierung und
                    denkmalpflegerischer Sanierung. Baurechtliche Beratung und Optimierung bilden dabei die Basis . In der
                    Projektentwicklung kann darüber hinaus ein Netzwerk aus Rechtsberatung, Entwicklung und Vertrieb zur Seite gestellt
                    werden. Ein eingespieltes Team im Bereich Haustechnik, Energie und Statik garantieren eine optimierte Lösungsfindung
                    in allen technischen und konstruktiven Bereichen. Durch die Beschäftigung weiterer Architekturbüros in
                    Form von Arbeitsgemeinschaften kann das Büro auf erweiterbare Kapazitäten zurückgreifen.
                  </>
                } />
              <TextboxBig
                hasNoMarginPlaceholder={true}
                title={'Leistung:'}
                decsription={
                  <>
                    <span>Der Leistungsumfang beinhaltet alle Leistungsphasen der Architektur, Innenarchitektur und Landschaftsarchitektur.
                      Beginnend mit derbaurechtlichen Optimierung mit Städten und Gemeinden, werden Projektevon Anfang an begleitet
                      und entwickelt. Der Entwurf und die Planung werden durch alle Stadien gemeinsam mit dem Bauherren in Abstimmung
                      erarbeitet. Durch ein erprobtes Netzwerk von Firmen werden Leistungen auf ökonomischer Grundlage des Wettbewerbs vergeben. Alle Projekte werden unter Einbezug ökologischer und ökonomischer Aspekte gedacht.
                      Die baukünstlerische, atmosphärische Gesamtbetrachtung steht dabei im Vordergrund. Das Selbstverständliche in Nutzung und Erscheinung ist Kern der Aufgabe.
                      <br /> <br /> Geschaffenes wird vergessen werden und in der Gegenwart bewahrt sein. Veraltet ist nur das Mislungene, das
                      gebrochene Versprechen des Neuen.</span>
                    <span style={{ textAlign: 'end' }}>Adomo</span>
                  </>
                } />
            </div>
          </Page>
          <FooterPartner></FooterPartner>
        </div>
      </div>
    );
  }
}
