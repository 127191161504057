import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Kontakt.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { TopbarPartner } from '../../directives/components/topbar/TopbarPartner';
import { FooterPartner } from '../../directives/components/FooterPartner';
import { TextboxBig } from '../../directives/components/util/TextboxBig';

export default class Kontakt extends React.Component<{}> {
  public render() {

    return (
      <div >
        <div>
          <div className={`${Container({ xs: "column start stretch" })}`} style={{ color: "#5f5f5f", minHeight: "100vh", width: "100vw" }}>
            <div className={`${Container("column center stretch")}`}>
              <div className={`${Container("column center center")}`}>
                <div className="mainComp" style={{ minHeight: "89vh" }}>
                  <TopbarPartner></TopbarPartner>
                  <div className={`${Container("column start stretch")} `}>
                    <div className={`${Container("column between stretch")} `} style={{ paddingTop: 36, color: "#adadad", lineHeight: 2 }}>
                      <div>Ziersch Architekten Partnerschaft GmbB</div>
                      <div>Grawolfstraße 1, 82166 Gräfelfing </div>
                      <a className="fakelink" href="tel:+49-89-85483804">089 85484804</a>
                      <a className="fakelink" href="mailto:info@za-p.de">info@za-p.de</a>
                    </div>
                    <div className={`${Container("column between stretch")} `} style={{ paddingTop: 36, color: "#adadad", lineHeight: 1.5 }}>
                      <a className="fakelink" href="https://www.instagram.com/ziersch.architektenundplaner/">Instagram</a><br></br>
                    </div>
                  </div>
                </div>
                <FooterPartner></FooterPartner>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
