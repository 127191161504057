import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './ArchivPartner.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { FooterPartner } from '../../directives/components/FooterPartner';
import { TextboxBig } from '../../directives/components/util/TextboxBig';
import { TopbarPartner } from '../../directives/components/topbar/TopbarPartner';

export default class ArchivPartner extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
          <Page>
            <TopbarPartner></TopbarPartner>
            <div className={`${Container("column start stretch")} `}>
              <TextboxBig noLetterSpacing={true} noLineHeightCHange={true} noWordSpacing={true} smallFont={true}
                title={'Archiv Projekte Gewerbe:'}
                decsription={
                  <>
                    <span>
                      Sanierung | Maximilianstraße 25 München | Bauherr: Münchener Rückversicherung | Baujahr: 1998 Bauvolumen:<b>0,6 mio. LP 1 - 9 </b> || Psychotherapeutische Spezialklinik |
                      Bad Tölz | Baujahr: 1998 Bauvolumen: <b>4 mio. LP 1 - 3 </b> || Gewerbe- und Wohnbebauung – Pelkovenstraße 54 | München | Baujahr: 1999 | Bauvolumen:<b> 2 mio. LP 1 - 5 </b> ||
                      Umbau eines Großraumbüros in Tonstudios in München | Bauherr: Firma ARRI | Baujahr: 2001 | Bauvolumen: <b>0,2 mio. LP 1 - 3 </b> || Freizeitbad Kempten | Bauherr: KKU. |
                      Baujahr: 2002 | Kooperation mit RPM | Bauvolumen:<b> 21 Mio. LP 5  </b>|| Rathausgalerie Innsbruck | Bauherr: Rathauspassage GmbH | Baujahr: 2002 | Kooperation mit RPM |
                      Bauvolumen: <b>43 mio. LP 5 </b> || Privatpraxis in der Grünwalderstraße, München | Baujahr: 2003 | Bauvolumen:<b> 0,1 mio. LP 1 - 9 </b> || Gemeinschaftspraxis am Ostbahnhof,
                      München | Baujahr: 2003 | Bauvolumen: <b>0,3 mio. LP 1 - 8  </b>|| Zentrum für dementielle Erkrankungen Fürstenried | Bauherr: Danuvius GmbH | Baujahr: 2005 | Bauvolumen:
                      <b>7 mio. LP 1 - 2 </b> || Zentrum für dementielle Erkrankungen Ingolstadt | Bauherr: Ingenium Stiftung | Baujahr: 2005 | Bauvolumen: <b>5 mio. LP 1 - 8  </b>|| Praxis Umbau |
                      Haimhauserstraße, München | Baujahr: 2006 | Bauvolumen:<b> 0,3 mio. LP 1 - 9 </b> || Jacob und Marie Rothenfußer - Gedächtnisstiftung Außenanlage | Bauherr: s.o. | Baujahr:
                      2006 | Bauvolumen: <b>0,1 mio. LP 1 - 8 </b> || Praxis Neugestaltung | Leopoldstraße, München | Baujahr: 2007 | Bauvolumen: <b>0,1 mio. LP 1 - 9 </b> || Umbau Verwaltungsetage |
                      Ingolstadt am Rathausplatz | Bauherr: Danuvius Klinik | Baujahr: 2007 | Bauvolumen: <b>0,2 mio. LP 1 - 5 </b> || Praxis Neugestaltung mit Umbau | Barmherzige Brüder |
                      München | Baujahr: 2011 | Bauvolumen: <b>0,1 mio. LP 1 - 9  </b>|| Praxis Neugestaltung mit Umbau und Brandschutzsanierung | Maximilianstraße , München | Baujahr: 2013 |
                      Bauvolumen:<b>0,5 mio. LP 1 - 9  </b>|| Neubau und Sanierung eines Gebäudeteils des Klosters Schäftlarn | Baujahr: 2014 | Bauvolumen: <b>3,5 mio. LP 1 - 3  </b>||
                    </span>
                  </>
                } />
              <TextboxBig noLetterSpacing={true} noLineHeightCHange={true} noWordSpacing={true} smallFont={true}
                title={'Archiv Projekte Öffentlichkeit:'}
                decsription={
                  <>
                    <span>
                      Erweiterung der Ernst-Barlach Schule | Bauherr: Stiftung Pfennigparade | Projektarchitekt bei RPM | Baujahr: 1996 | Bauvolumen:<b> 20 mio. LP 1 - 8 </b>|| Umbau und
                      Sanierung des Nordbades | Bauherr: Stadt München | Projektarchitekt bei RPM | Baujahr: 1997 | Bauvolumen: <b>4 mio. LP 1 - 8</b> || Einbau von Schulräumen in ehemalige
                      Lager- und Büroräume | Bauherr: Stiftung Pfennigparade | Projektarchitekt bei RPM | Baujahr: 1998 | Bauvolumen:<b> 0,6 mio. LP 1 - 8</b> || Umbau und Sanierung der
                      Eingangshalle – Deutsches Theater | Bauherr: DT- Betriebs- GmbH | Baujahr: 2000 | Bauvolumen: <b>0,7 mio. LP 1 - 8 </b>|| Umbau und Erweiterung mit Saunalandschaft
                      Stadtbad Germering | Bauherr: Stadt Germering | Baujahr: 2001 | Bauvolumen:<b> 5 mio. LP 1 - 3</b> || Umbau des Denkmal geschützten Bades des Nato- Stützpunktes FFB
                      Bauherr: Bundesrepublik Deu . | Baujahr: 2002 und 2005/6 | Bauvolumen:<b> 2 mio. LP 1 - 9 </b>|| Umbau und Erweiterung Stadtbad Germering | Bauherr: Stadt Germering |
                      Baujahr: 2001 | Bauvolumen: <b> 1,5 mio. LP 1 - 8</b> || Sanierung diverser Gebäude und Fassaden des Nato- Stützpunktes FFB | Bauherr: Bundesrepublik Deutschland |
                      Baujahr: 2008 bis 2014 | Bauvolumen: <b> 2,5 mio. LP 1 - 9 </b>|| Brandschutzsanierung des Denkmal geschützten Rasso Klosters in Grafrath | Bauherr: Bundesrepublik
                      Deutschland | Baujahr: 2014 und 2015 | Bauvolumen: <b> 0,5 mio. LP 1 - 9 </b>|| Fassadensanierung der Denkmal geschützten Rasso Kirche in Grafrath | Bauherr:
                      Bundesrepublik Deutschland | Baujahr: 2014 und 2015 | Bauvolumen: <b> 0,2 mio. LP 1 - 9 </b>|| Brückensanierung des Denkmal geschützten Rasso Klosters in Grafrath |
                      Bauherr: Bundesrepublik Deutschland | Baujahr: 2014 und 2015 | Bauvolumen: <b> 0,6 mio. LP 1 - 9 </b>|| Neubau eines Ziegenhofes mit Melkbetrieb als Umweltschutzprojekt |
                      Bauherr: Stiftung Adelegg | Baujahr: 2015 | Bauvolumen: <b> 0,8 mio. LP 1 - 8 </b>|| Neubau eines gemeindlichen Bauhofes | Bauherr: Gemeinde Gräfelfing | Baujahr: 2019 |
                      Bauvolumen: <b> 5 mio. LP 1 - 3 </b>|| Sanierung eines Mehrfamiliehauses mit Inklusionscafe | Bauherr: Gemeinde Gräfelfing | Baujahr: 2019 | Bauvolumen: <b> 0,6 mio. LP 1 - 3 </b>||
                      Aufstockung der Klinik für Psychiatrie und Psychiotherapie | am Standort der Ilmtalklinik | Pfaffenhofen | Bauherr: Danuvius Klinik | Baujahr: 2018/2020 | Bauvolumen: <b> 4,5
                        mio. LP 1 - 9 </b>|| Nebengebäude an der Klinik für Psychiatrie und Psychiotherapie | am Standort der Ilmtalklinik, Pfaffenhofen | Bauherr: Danuvius Klinik | Baujahr:
                      2018/2020 | Bauvolumen: <b> 6,5 mio. LP 1 - 9 </b>|| Sanierung eines Mehrfamiliehauses | Bauherr: Gemeinde Planegg Baujahr: 2019 | Bauvolumen: <b> 0,4 mio. LP 1 - 4 </b>||
                      Sanierung und Erweiterung eines Gerichtsgebäudes | Bauherr: Bundesrepublik Deutschland | Baujahr: 2019/2020 | Bauvolumen: <b> 2,2 mio. LP 1-9</b> ||
                    </span>
                  </>
                } />
              <TextboxBig noLetterSpacing={true} noLineHeightCHange={true} noWordSpacing={true} smallFont={true}
                title={'Archiv Projekte Privat:'}
                decsription={
                  <>
                    <span>
                      Wohnbebauung in der Stievestraße, München | Baujahr: 1997 | Bauvolumen:
                      <b> 2 mio. LP 1 - 3</b> || Umbau und Renovierung Gut Haingschwendt | Miesbach | Baujahr: 1998 |
                      Bauvolumen:
                      <b> 0,2 mio. LP 1 - 9 </b>|| Umbau und Anbau Hanfstängelstrraße | München | Baujahr: 1999 | Bauvolumen:
                      <b> 0,5 mio. LP 1 - 5 </b>| Komplettsanierung und Ausbau
                      einer denkmalgeschützten Villa in Bogenhausen | Ebersbergerstraße, München | Baujahr: 2000 | Bauvolumen:
                      <b> 1 mio. LP 1 - 9 </b>|| Komplettsanierung und Erweiterung
                      eines Einfamilienhauses | Frauenchiemseestraße, München | Baujahr: 2000 | Bauvolumen:
                      <b> 0,3 mio. LP 1 - 9 </b>|| Errichtung einer Ferienanlage auf Ischia- Italien | Baujahr:
                      2000 Bauvolumen:
                      <b> 0,6 Mio. LP 1 - 9 </b>|| Komplettsanierung Maréesstraße, München | Baujahr: 2001 | Bauvolumen:
                      <b> 0,4 mio. LP 1 - 9 </b>|| Komplettsanierung und
                      Erweiterung St. Ingbertstraße, München | Baujahr: 2002 | Bauvolumen:
                      <b> 0,3 mio. LP 1 - 9</b>|| Komplettsanierung und Erweiterung eines Einfamilienhauses mit Atelier in
                      Utting | Baujahr: 2002 | Bauvolumen:
                      <b> 0,4 mio. LP 1 - 9</b> || Neubau eines 2-Familienhauses | Karwendelstraße, Pullach | Baujahr: 2003 | Bauvolumen:
                      <b> 0,6 mio. LP 1 - 9 </b>||
                      Komplettsanierung Sondermeierstraße, München | Baujahr: 2003 | Bauvolumen:
                      <b> 0,3 mio. LP 1 - 9</b> || Neubau eines Einfamilienhauses | Memelerstraße, München | Baujahr:
                      2004 | Bauvolumen:
                      <b> 0,5 mio. LP 1 - 9</b> || Neubau von 4 Doppelhaushälften | Kisslingerstraße , Rottach- Egern | Baujahr: 2004/5 | Bauvolumen:
                      <b> 1,1 mio. LP 1 - 9 </b>|| Neubau
                      eines Einfamilienhauses | Unterhaching, München | Baujahr: 2005/6 | Bauvolumen:
                      <b> 0,4 mio. LP 1 - 9</b> || Neubau eines Einfamilienhauses | Karl-Stielerstraße,
                      Dachau/Karlsfeld | Baujahr: 2005/6 | Bauvolumen:
                      <b> 0,4 mio. LP 1 - 9 </b>|| Komplettsanierung eines Einfamilienhauses | Schönchenstraße, München | Baujahr: 2005/6 |
                      Bauvolumen:
                      <b> 0,3 mio. LP 1 - 9</b> || Neubau eines Aussenaufzuges mit Haussanierung eines Mehrfamilienhauses | Agnesstarße , München Schwabing | Baujahr: 2005/6 |
                      Bauvolumen:
                      <b> 1,1 mio. LP 1 - 9 </b>|| Komplettsanierung eines denkmalgeschützten Mehrfamilienhauses in Abschnitten mit Ausbau des Dachgeschosses Bruderstraße 1,
                      München Lehel | Baujahr: 2005 - 2017 | Bauvolumen:
                      <b> 3 mio. LP 1 - 9 </b>|| Sanierung eines historischen Hauses bei Steinheim im Allgäu Baujahr: 2007 | Bauvolumen:
                      <b> 0,2 mio. LP 1 - 9</b> || Sanierung eines Einfamilienhauses in der Dreschstraße, München | Baujahr: 2007 | Bauvolumen:
                      <b> 0,3 mio. LP 1 - 9 </b>|| Neubau eines Einfamilienhauses |
                      Dreschstraße, München | Baujahr: 2008 | Bauvolumen:
                      <b> 1,0 mio. LP 1 - 9 </b>|| Sanierung mit Anbau eines Einfamilienhauses | Haimstettanallee , Planegg | Baujahr: 2008 |
                      Bauvolumen:
                      <b> 0,6 mio. LP 1 - 9</b> || Sanierung mit Aufstockung eines Einfamilienhauses | Tintorettostraße, München | Baujahr: 2008 | Bauvolumen:
                      <b> 0,3 mio. LP 1 - 9 </b>||
                      Sanierung eines Baudenkmals | Friedrichstraße, München | Baujahr: 2009 | Bauvolumen:
                      <b> 0,5 mio. LP 1-9 </b>|| Neubau eines Einfamilienhauses | Dagelfing, München |
                      Baujahr: 2010 | Bauvolumen:
                      <b> 0,7 mio. LP 1 - 3 </b>|| Sanierung eines Hausteils | Artur Kutscher Platz, München | Baujahr: 2010 | Bauvolumen:
                      <b> 0,25 mio. LP 1 - 9 </b>||
                      Erweiterung eines Einfamilienhauses | Penzberg | Baujahr: 2011 | Bauvolumen:
                      <b> 0,2 mio. LP 1 - 3 </b>|| Ausbau eines Einfamilienhauses | Kitzbühel, Österreich | Baujahr: 2012
                      | Bauvolumen:
                      <b> 0,25 mio. LP 1 - 9</b> || Erweiterung und Sanierung eines Einfamilienhauses | Blütenring, München | Bauherr: Familie Felbinger Baujahr: 2013 | Bauvolumen: <b>
                        1 mio. LP 1 - 9 </b>|| Sanierung eines Einfamilienhauses in Gräfelfing | Baujahr: 2014 | Bauvolumen:
                      <b> 0,2 mio. LP 1 - 9</b> || Sanierung eines Mehrfamilienhauses |
                      Mitterwieserstraße, München | Baujahr: 2014 | Bauvolumen:
                      <b> 0,6 mio. LP 1 - 9 </b>|| Umbau eines Einfamilienhauses | Mathildenstraße , Gräfelfing | Baujahr: 2014 |
                      Bauvolumen:
                      <b> 0,3 mio. LP 1 - 6 </b>|| Neubau von 2 Doppelhäusern | München, Neumeyerstrasse | Baujahr: 2014/15 | Bauvolumen:
                      <b> 1,2 mio. LP 1 - 6</b> | Sanierung eines
                      Einfamilienhauses | Denning, München | Baujahr: 2015 | Bauvolumen:
                      <b> 0,5 mio. LP 1 - 9 </b>|| Sanierung eines Einfamilienhauses | Gräfelfing | Baujahr: 2015 | Bauvolumen: <b>
                        0,1 mio. LP 1 - 9 </b>|| Sanierung eines Einfamilienhauses | Berg | Baujahr: 2015 | Bauvolumen:
                      <b> 0,5 Mio . LP 1 - 9</b> || Neubau eines Mehrfamilienhauses | Kitzbühel | Baujahr:
                      2015/16 | Bauvolumen:
                      <b> 2,2 mio. LP 1 - 8 </b>|| Neubau von 2 Einfamilienhäusern | Gräfelfing | Baujahr: 2015/16 | Bauvolumen:
                      <b> 1,7 mio. LP 1 - 8</b> || Sanierung |
                      Mitterwieserstraße, München | Baujahr: 2015/16 | Bauvolumen:
                      <b> 0,6 mio. LP 1 - 8</b> || Sanierung mit Erweiterung eines Einfamilienhauses | Gräfelfing | Baujahr: 2016/17 |
                      Bauvolumen:
                      <b> 0,6 mio. LP 1 - 8</b> || Neubau eines Einfamilienhauses | Gräfelfing | Baujahr: 2018 | Bauvolumen:
                      <b> 2,6 mio. LP 1 - 4</b> || Neubau eines Doppelhauses | Starnberg
                      | Baujahr: 2018 | Bauvolumen:
                      <b> 2,2 mio. LP 1 - 4 </b>| Sanierung eines Einfamilienhauses | Dreschstraße, München | Baujahr: 2019 | Bauvolumen:
                      <b> 0,2 mio. LP 1 - 9 </b>||
                      Sanierung Einfamilienhauses | Gräfelfing | Baujahr: 2019/2020 | Bauvolumen:
                      <b> 1,6 mio. LP 1 - 8 </b>| Sanierung eines Mehrfamilienhauses | Pasing | Baujahr: 2020 |
                      Bauvolumen:
                      <b> 1,8 mio. LP 1 - 8 </b>|| Sanierung eines Einfamilienhauses | Gräfelfing | Baujahr: 2016/17 | Bauvolumen:
                      <b> 0,6 mio. LP 1 - 3 </b>||
                    </span>
                  </>
                } />
              {/* <TextboxBig spec={true} noLetterSpacing={true} noLineHeightCHange={true} noWordSpacing={true} smallFont={true}
                title={'Archiv Mitarbeiter:'}
                decsription={
                  <>
                    <span>
                      Ziersch Architekten<br />
                      Partnerschaft GmbB<br />
                      Grawolfstraße 1 82166 Gräfelfing<br />
                      www.za-p.de 089 85484804 info@za-p.de<br />
                    </span>
                  </>
                } /> */}
            </div>
          </Page>
          <FooterPartner></FooterPartner>
        </div>
      </div>
    );
  }
}
