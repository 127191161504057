import React from "react";
import './topbar.css';

import ZAP_Logo from '../../../images/logos/ZAP_logo_Clean.png';


import { Container, hide } from "@quesstor/react-flex-layout"
import TopbarLink from "./TopbarLink";
import { Icon } from "../util/Icon";
import { Hr } from "../util/Hr";
import { Landing } from "../../../pages/Landing";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { relative } from "path";

export class TopbarPartner extends React.Component<{
    variant?: "transparent" | "white" | "hidden" | "help"
    positionFixed?: boolean;
    disableSidebar?: boolean;
    hideLogo?: boolean;
    showUpArrow?: boolean;

    hideMargin?: boolean;
}> {
    state = { showSidebar: false }
    render() {
        const NavigationItems =
            <>
                {this.props.showUpArrow && <OverlayTrigger
                    placement="auto"
                    overlay={<Tooltip id="button-tooltip-2">Zurück zum Anfang</Tooltip>}
                ><div onClick={() => {
                    if (Landing.Top && Landing.Top.current) {
                        Landing.Top.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start"
                        });
                    }
                }}
                    style={{ cursor: "pointer", fontSize: 20, marginTop: 10 }}>
                        <Icon icon="sort-up"></Icon>
                    </div>
                </OverlayTrigger>}
                <TopbarLink
                    link={'/Partnerschaften/Aktuell'}
                    key={'AKTUELL'}
                    title="AKTUELL"
                />
                <TopbarLink
                    link={'/Partnerschaften/Profil'}
                    key={'Profil'}
                    title="Profil"
                />

                <TopbarLink
                    link={'/Partnerschaften/Projekte'}
                    key={'Projekte'}
                    title="Projekte"
                />
            </>
        const NavigationItems2 =
            <>
                <TopbarLink
                    link={'/Partnerschaften/Team'}
                    key={'Team'}
                    title="Team"
                />
                <TopbarLink
                    link={'/Partnerschaften/Archiv'}
                    key={'Archiv'}
                    title="Archiv"
                />
            </>
        const NavigationItems3 =
            <>
                <TopbarLink
                    link={'/Partnerschaften/Kontakt'}
                    key={'Kontakt'}
                    title="Kontakt"
                />
            </>
        const NavigationItemsSideBarBottom =
            <>
                <TopbarLink
                    link={'https://www.instagram.com/ziersch.architektenundplaner/'}
                    key={'instagram'}
                    title="instagram"
                />
                {/* <a style={{ position: 'relative' }} href={"https://www.instagram.com/ziersch.architektenundplaner/"}

                    className={`white Topbarlink`} >
                    <div className={`${Container("row start center")} innerText`} style={{ position: 'relative' }}>
                        <div style={{ color: "#adadad", textTransform: 'uppercase' }} >instagram</div>
                    </div >
                </a > */}
                <TopbarLink
                    link={'/Partnerschaften/Datenschutz'}
                    key={'Datenschutz'}
                    title="Datenschutz"
                />
                <TopbarLink
                    link={'/Partnerschaften/Impressum'}
                    key={'Impressum'}
                    title="Impressum"
                />

            </>
        return (
            <>

                <div style={{}} className={`topBarPadding ${Container("column center stretch")} marginPlaceholder`}>
                    <div className={`${Container("row between end")} topbar  hideOnPrint topbar`} style={{ flexWrap: "nowrap", margin: this.props.hideMargin ? "0" : "", background: "white", zIndex: 1, width: '100%', position: this.props.positionFixed == true ? 'fixed' : 'initial' }}>
                        {/* ${css[variant]} */}
                        {!this.props.hideLogo && <a className={`nofakelink`} href="/">
                            <div className={`${Container("row start stretch")}`}>
                                <img src={ZAP_Logo} className="App-logo" alt="logo" style={{ marginBottom: 53, objectFit: "cover", height: 142 }} />
                            </div>
                            <span className={`topbarSize fontBlack`} style={{ color: "#5f5f5f" }} >Architekten Partnerschaft GmbB</span>
                        </a>}
                        {/* {!this.props.hideLogo && <a href="/" style={{height:'80px', width:'150px'}} >
                             <div className="embed-responsive embed-responsive-16by9">
                                <video src="/Logo_1920x1080.mp4" controlsList="nodownload" autoPlay={true} muted style={{}}  ></video>
                            </div>
                            </a>
                            } */}


                        <div className={`${Container("row around center")} ${!this.props.disableSidebar && hide.sm_sm}`} >
                            {NavigationItems}
                            {NavigationItems2}
                            {NavigationItems3}
                        </div>
                        <div className={hide.md_gt}><br></br></div>
                        {!this.props.disableSidebar && <div className={hide.md_gt}>
                            {!this.state.showSidebar && <span style={{ top: "20px", position: "relative", color: "#5f5f5f", fontSize: 45, fontWeight: "bold" }} onClick={() => this.setState({ showSidebar: true })}>+</span>}

                            {this.state.showSidebar && <><span style={{ top: "20px", position: "relative", zIndex: 25, color: "#5f5f5f", fontSize: 45, fontWeight: "bold" }} onClick={() => this.setState({ showSidebar: false })}>&times;</span>

                                {/* <Icon icon="plus" /> */}
                            </>}
                            {this.state.showSidebar &&
                                <div style={{ zIndex: 5 }} className={`sidebarcontainer animated fadeIn faster`} onClick={() => this.setState({ showSidebar: false })}>
                                    <div className={`${Container("column around stretch")} sidebar animated fadeInRight faster`} onClick={e => e.stopPropagation()}>
                                        <div style={{}}>
                                            {/* <div className={`topbar ${Container("row between center")}`} style={{ fontSize: 20 }}>
                                                 <div className={`${Container("column center stretch")}`}>
                                                    <img src={ZAP_Logo} className="App-logo" alt="logo" style={{ objectFit: "cover", height: 44 }} />
                                                </div> 
                                            </div> */}

                                            <div className={``}>

                                                {/* <Hr style={{ margin: "0 0 8px 0" }}>PROFIL</Hr> */}
                                                {NavigationItems}
                                            </div>

                                            <div className={``}>
                                                {/* <Hr style={{ margin: "0 0 8px 0" }}>KONTAKT & TEAM</Hr> */}
                                                {NavigationItems2}
                                            </div>
                                            <div className={``}>
                                                {/* <Hr style={{ margin: "0 0 8px 0" }}>VERGANGENES</Hr> */}
                                                {NavigationItems3}
                                            </div>
                                        </div>
                                        <div className={``} style={{ fontSize: 12 }}>{NavigationItemsSideBarBottom}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        }
                    </div >
                </div>
            </>
        )
    }
}
