import { Container, Flex } from "@quesstor/react-flex-layout";
import React from "react";
import { Icon } from "./Icon";
import './TextboxBig.css';

export class TextboxBig extends React.Component<{
    title?: string;
    decsription?: string | JSX.Element;
    link?: string;
    bg?: boolean;
    border?: boolean;
    hasPadding?: boolean;
    hasNoMarginPlaceholder?: boolean;
    spec?: boolean;
    onThreeAspects?: boolean;
    noWordSpacing?: boolean;
    noLetterSpacing?: boolean;
    noLineHeightCHange?: boolean;
    smallFont?: boolean;
}> {

    render() {

        return (
            <div className={` ${Container("column start stretch")}`}
                style={{
                    border: this.props.border ? '0.5px solid black' : "",
                    background: this.props.bg ? "white" : "transparent",
                    padding: this.props.hasPadding ? "8px 16px" : ""
                }} >
                <div style={{ color: "#adadad" }} className={`marginPlaceholder-small  ${Container({ xs: "column start stretch", sm: "row start stretch" })} `}>
                    {/* <div style={{ fontSize: "14px" }} className={`textBlock  ${Container("column start stretch")} ${Flex({ xs: { flex: this.props.onThreeAspects ? "1" : "1" } })}`}>
                        {this.props.title}
                    </div> */}
                    <div style={{ fontSize: this.props.smallFont ? "1.7vh" : "2.2vh", wordSpacing: this.props.noWordSpacing ? "0px" : "8px", lineHeight: this.props.noLineHeightCHange ? 1.5 : 1.8 }} className={`textBlock`}>
                        <span style={{ fontSize: this.props.smallFont ? "1.7vh" : "2.2vh", letterSpacing: this.props.noLetterSpacing ? "0px" : "8px" }}>{this.props.title}</span>
                        <span style={{ lineHeight: this.props.noLineHeightCHange ? 1.5 : 3 }}>{this.props.decsription}</span>
                    </div>
                </div>
            </div>
        )
    }
}  
