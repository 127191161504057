import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './DatenschutzPlanung.css';
import 'react-awesome-slider/dist/styles.css';

import Datenschutz from '../Datenschutz';

export default class DatenschutzPlanung extends React.Component<{}> {
  public render() {

    return (
      <div style={{ width: "100vw", minHeight: "100vh" }}>
        <div>
        <Datenschutz topbarPlanung={true}/>
        </div>
     </div>
    );
  }
}
