import { Container, Flex } from '@quesstor/react-flex-layout';
import * as React from 'react';

import './Kontakt.css';
import 'react-awesome-slider/dist/styles.css';

import { Page } from '../../directives/pagepresets/Page';
import { TopbarPlanung } from '../../directives/components/topbar/TopbarPlanung';
import { FooterPlanung } from '../../directives/components/FooterPlanung';
import { TextboxBig } from '../../directives/components/util/TextboxBig';

export default class KontaktPlanung extends React.Component<{}> {
  public render() {

    return (
      <div >
        <div className={`${Container({ xs: "column start stretch" })}`} style={{ color: "#5f5f5f", minHeight: "100vh", width: "100vw" }}>
          <div className={`${Container("column center stretch")}`}>
            <div className={`${Container("column center center")}`}>
              <div className="mainComp" style={{ minHeight: "89vh" }}>
                <TopbarPlanung></TopbarPlanung>
                <div className={`${Container("column start stretch")} `} style={{}}>
                  <div className={`${Container("column between stretch")} `} style={{ paddingTop: 36, color: "#adadad", lineHeight: 2 }}>
                    <div>Ziersch Planungs GmbH</div>
                    <div>Grawolfstraße 1, 82166 Gräfelfing </div>
                    <a className="fakelink" href="tel:+49-89-85483804">089 85484804</a>
                    <a className="fakelink" href="mailto:info@za-p.de">info@za-p.de</a>
                    <a className="fakelink" href="https://www.instagram.com/ziersch.architektenundplaner/">Instagram</a><br></br>
                  </div>
                  <div className={`${Container("column between stretch")} `} style={{ paddingTop: 36, color: "#adadad", lineHeight: 1.5 }}>

                    <div>Bertold Ziersch</div>
                    <div>
                      Mitglied des BAB, ehrenamtlicher Bauberater der Gemeinde Gräfelfing, Mitglied der Alzheimergesellschaft Ingolstadt, Gründungsmitglied
                      und ehrenamtlicher Architekt der StiftungKulturlandschaft Adelegg
                    </div>
                  </div>
                </div>
              </div>
              <FooterPlanung></FooterPlanung>

            </div>
          </div>
        </div>

      </div >
    );
  }
}
