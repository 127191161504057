import { Container, Flex } from "@quesstor/react-flex-layout";
import React from "react";
import { Icon } from "./util/Icon";

export class FooterPartner extends React.Component<{}> {
  render() {
    return (
      <div className={`${Container("column center center")} marginPlaceholder-small`} >
        <div className={`${Container("column center stretch")}`} style={{ padding: 16, color: "#adadad", background: "#white", width: "calc(95vw - 16px)" }}>
          <div className={`${Container("column center center")}`} >
            <div className={` ${Container("row around stretch")}`} style={{ fontSize: "1.5vh" }}>
              <a href="https://www.instagram.com/ziersch.architektenundplaner/">
                <Icon from={'font-awesome-brand'} icon={'instagram'} style={{ color: "#5f5f5f", padding: '0 8px', fontSize: 25 }}></Icon>
              </a>
              <div className={` ${Container("row center center")}`}>
                <a className={` fakelink`} href="/Partnerschaften/Impressum" style={{ color: window.location.pathname == "/Partnerschaften/Impressum" ? "#5f5f5f" : "#adadad", fontWeight: 'normal', margin: '0 8px' }} >IMPRESSUM</a>
                <a className={` fakelink`} href="/Partnerschaften/Datenschutz" style={{ color: window.location.pathname == "/Partnerschaften/Datenschutz" ? "#5f5f5f" : "#adadad", fontWeight: 'normal', padding: '0 8px' }}>DATENSCHUTZ</a>
                <a className={``} href="mailto:info@za-p.de" style={{ color: "#adadad", fontWeight: 'normal', padding: '0 8px' }}>info@za-p.de</a>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}
